// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ProjectsListWrapper,
  StyledInfoTitles,
  StyledTitles,
} from './ProjectsListStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import ProjectCard from '../ProjectCard/ProjectCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IProject } from '@/models/projects.model';

// Hooks
import { useInfiniteScroll } from '@/hooks';

interface IProjectsList {
  projects: {
    items: IProject[] | null;
    hasMore: boolean;
  };
  getNextProjects: () => void;
  isLoading: boolean;
}

const ProjectsList = ({
  projects,
  getNextProjects,
  isLoading,
}: IProjectsList) => {
  const { t } = useTranslation(['projectsList']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedProjectsList = useMemo(() => {
    return projects?.items?.map((projectItem) => {
      return <ProjectCard project={projectItem} key={projectItem?.id} />;
    });
  }, [projects?.items]);

  useInfiniteScroll({
    callback: getNextProjects,
    ref: loaderRef,
    isLoading,
    hasMore: projects?.hasMore,
  });

  return (
    <ProjectsListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('projects_list_project_title')}</StyledTitles>
          <StyledTitles>{t('projects_list_contact_title')}</StyledTitles>
          <StyledTitles>{t('projects_list_contact_person_title')}</StyledTitles>
          <StyledTitles>{t('projects_list_community_title')}</StyledTitles>
          <StyledTitles>{t('projects_list_geography_title')}</StyledTitles>
          <StyledTitles>{t('projects_list_status_title')}</StyledTitles>
          <StyledTitles>{t('projects_list_details')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedProjectsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </ProjectsListWrapper>
  );
};

export default ProjectsList;
