// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  PayoutCardWrapper,
  StyledInfoWrapper,
  StyledFileButton,
  StyledFileIconWrapper,
  StyledFrame,
  StyledChannelsWrap,
} from './FundingRoundDetailsCardStyles';
import { StyledText, StyledTitle } from '../Styled';
import { Link } from '@/ui';
import { TextFileIcon } from '@/icons';
import PayoutActionsBadges from './PayoutActionsBadges';

// Models
import { IFundingPayoutDetails } from '@/models/payout.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';
import { socialChannelsConfig } from '@/modules/KolsList';

// Helpers
import { numberWithCommas } from '@/utils';

interface IFundingRoundDetailsCard {
  data: IFundingPayoutDetails;
}

const FundingRoundDetailsCard = ({ data }: IFundingRoundDetailsCard) => {
  const { t } = useTranslation(['payout']);

  const socialChannels = useMemo(() => {
    return data?.projectSocialChannel?.map((item) => {
      return (
        <Link key={item?.name} to={item?.link ?? ''} target="_blank">
          <StyledFrame>{socialChannelsConfig[item?.name]?.icon}</StyledFrame>
        </Link>
      );
    });
  }, [data]);

  return (
    <PayoutCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_project_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.projectTitle}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_contact_title')}</StyledTitle>
        </MobileOnly>
        <StyledChannelsWrap>{socialChannels ?? '-'}</StyledChannelsWrap>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_wallet_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.walletAddress}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_document_title')}</StyledTitle>
        </MobileOnly>
        <StyledFileButton type="ghost">
          {t('payout_service_agreement_title')}
          <StyledFileIconWrapper>
            <TextFileIcon />
          </StyledFileIconWrapper>
        </StyledFileButton>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_transfer_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>${numberWithCommas(data?.transferAmount ?? 0)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_currency')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.walletCurrency}</StyledText>
      </StyledInfoWrapper>
      <PayoutActionsBadges data={data} />
    </PayoutCardWrapper>
  );
};

export default FundingRoundDetailsCard;
