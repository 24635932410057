// Globals
import React from 'react';

// Components
import { TableRow, TableCell, NetworkTitle } from './BalanceReportStyles';
import { Tooltip } from '@/ui';

// Modules
import { networksConfig } from '@/modules/Web3';

// Models
import { TNetworkTransfersData } from '@/models/reports.model';
import { WalletNetworksEnum, CryptoCurrenciesEnum } from '@/models/web3.model';

// Utils | Helpers
import { renderCurrenciesValues } from '../../helpers/dataRender';
import { capitalizeString, numberWithCommas } from '@/utils';

interface IBalanceReportRow {
  data: TNetworkTransfersData;
  network: WalletNetworksEnum;
  targetCurrency?: CryptoCurrenciesEnum;
  targetNetwork?: WalletNetworksEnum;
  nonTargetNetworksCommission?: number;
  totalCredits?: number;
  walletAddress?: string;
}
const BalanceReportRow = ({
  data,
  network,
  walletAddress,
  targetCurrency,
  nonTargetNetworksCommission,
  totalCredits,
  targetNetwork,
}: IBalanceReportRow) => {
  const isTargetNetwork = targetNetwork === network;
  const creditsTotal = data?.creditUsed?.totalByNetwork ?? 0;
  const balanceTransfer =
    (data?.seedHunterCommissionAmount?.totalByNetwork ?? 0) - creditsTotal;
  const targetCreditsRow = `+ ${targetCurrency} ${nonTargetNetworksCommission}`;
  const nonTargetCreditsRow = `- ${targetCurrency} ${balanceTransfer}`;

  return (
    <TableRow>
      <TableCell>
        <img src={networksConfig[network].image} alt="" />
        <NetworkTitle>{capitalizeString(network)}</NetworkTitle>
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title={walletAddress}
          placement={'top'}
        >
          <NetworkTitle>{walletAddress}</NetworkTitle>
        </Tooltip>
      </TableCell>

      <TableCell>
        {data.raisedBudget && renderCurrenciesValues(data.raisedBudget)}
      </TableCell>

      <TableCell>
        {data.creditUsed && renderCurrenciesValues(data.creditUsed)}
      </TableCell>

      <TableCell>
        {data.initialBalance && renderCurrenciesValues(data.initialBalance)}
      </TableCell>

      <TableCell>
        {`${targetCurrency} ${numberWithCommas(
          data.initialBalance?.totalByNetwork ?? 0,
        )}`}
      </TableCell>

      {isTargetNetwork ? (
        <TableCell>
          <div>{`+ ${totalCredits} (Credit)`}</div>
          {targetCreditsRow}
        </TableCell>
      ) : (
        <TableCell>
          <div>{`+ ${creditsTotal} (Credit)`}</div>
          {nonTargetCreditsRow}
        </TableCell>
      )}

      <TableCell>
        {data.finalBalance && renderCurrenciesValues(data.finalBalance)}
      </TableCell>
    </TableRow>
  );
};

export default BalanceReportRow;
