import { useTranslation } from 'react-i18next';
import {
  useGetEmailRule,
  useGetPasswordRule,
  useGetRequiredRule,
} from '@/hooks';
import { AuthFieldsNames } from '@/types';

const useGetFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });
  const email = useGetEmailRule({ t });
  const password = useGetPasswordRule({ t });

  return {
    [AuthFieldsNames.EMAIL]: [required, email],
    [AuthFieldsNames.PASSWORD]: [required, password],
  };
};

export default useGetFormValidationRules;
