import React, { useEffect } from 'react';
import { Spin } from 'antd';
import 'react-international-phone/style.css';

// Modules
import {
  useGetCryptoCurrencies,
  fetchSettings,
  fetchGeographies,
} from '@/modules/DataLists';
import {
  selectAuthIsLoading,
  selectIsAuthorized,
  useSessionRefresh,
  useUnauthorizedRedirect,
} from '@/modules/auth';
import { getAverageSocialData } from '@/modules/Settings';
import { getAdminPermissions } from '@/modules/Permissions';
import useRoutePermissions from './modules/Permissions/hooks/useRoutePermissions';
import { MainLayout } from './modules/Layout';
import { useSetIsMobile } from './modules/Adaptive';
import { DeploymentInProgress } from './modules/Errors';

// Helpers
import './config/dayjs.config';
import Router, { ROUTES_PATHS } from './router';
import { useAppDispatch, useAppState, useScript } from '@/hooks';
import { GOOGLE_PLACES_API_URL } from '@/api';
import { env } from './config/env';

const App = () => {
  const dispatch = useAppDispatch();
  const isAuthorized = useAppState(selectIsAuthorized);
  const isLoading = useAppState(selectAuthIsLoading);

  useSetIsMobile();
  useGetCryptoCurrencies();
  useSessionRefresh();
  useRoutePermissions();
  useUnauthorizedRedirect({
    pathname: ROUTES_PATHS.SIGN_IN,
  });

  useEffect(() => {
    if (isAuthorized) {
      dispatch(fetchSettings());
      dispatch(fetchGeographies());
      dispatch(getAverageSocialData());
      dispatch(getAdminPermissions());
    }
  }, [isAuthorized]);

  // Google maps API script
  useScript(GOOGLE_PLACES_API_URL);

  if (isLoading) return <Spin />;

  if (env.MAINTENANCE_MODE) return <DeploymentInProgress />;

  return (
    <div className="App">
      <MainLayout>
        <Router />
      </MainLayout>
    </div>
  );
};

export default App;
