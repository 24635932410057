// Globals
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { Title } from '@/ui';
import { GoBackButton } from '@/components';
import { FundingRoundPayoutDetails, StyledHeader } from '../../components';

// Models
import { IFundingPayoutDetails } from '@/models/payout.model';

// Helpers | Hooks
import { getUserId } from '@/utils';
import { useAppDispatch, useAppState } from '@/hooks';
import { payoutSliceSelector } from '../../feature/selectors';
import { getFundingPayoutDetailsList } from '../../feature/actionCreators';

const FundingRoundDetailsContainer = () => {
  const { t } = useTranslation(['payout']);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { fundingPayoutDetailsData, isLoading } =
    useAppState(payoutSliceSelector);

  useEffect(() => {
    dispatch(
      getFundingPayoutDetailsList({ fundingRoundId: getUserId(id ?? '') }),
    );
  }, [id]);

  return (
    <>
      <StyledHeader>
        <Title $type="h3">{t('payout_title')}</Title>
      </StyledHeader>
      <GoBackButton>{t('payout_go_back_button')}</GoBackButton>
      <FundingRoundPayoutDetails
        fundingPayoutDetails={fundingPayoutDetailsData as IFundingPayoutDetails}
        isLoading={isLoading}
      />
    </>
  );
};

export default FundingRoundDetailsContainer;
