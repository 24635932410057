// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import {
  KOLCardWrapper,
  StyledInfoWrapper,
  StyledText,
  StyledLinkWrapper,
  StyledTitle,
} from './PartnerCardStyles';
import { ArrowRightIcon } from '@/icons';
import { Link } from '@/ui';
import { ContactBar, CountBadge } from '@/components';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { IPartner } from '@/models/partnersList.model';

// Routes
import { ROUTES_PATHS } from '@/router';

// Helpers
import { PartnerDetailsTabKeys } from '../../constants';
import { getStatuses } from '@/types';

interface IPartnerCard {
  partner: IPartner;
}

const PartnerCard = ({ partner }: IPartnerCard) => {
  const { t } = useTranslation(['partnersList', 'common']);

  return (
    <KOLCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('hunters_list_name_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{`${partner?.firstName} ${partner?.lastName}`}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('hunters_list_contact_title')}</StyledTitle>
        </MobileOnly>
        <StyledLinkWrapper>
          <ContactBar data={partner} />
        </StyledLinkWrapper>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('hunters_list_status_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {t(getStatuses(t, partner?.status), { ns: 'common' })}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <StyledText>
          <Link
            to={`${ROUTES_PATHS.HUNTERS_LIST}/${encodeURIComponent(
              partner?.id ?? '',
            )}/${PartnerDetailsTabKeys.GENERAL_INFO}`}
          >
            <ArrowRightIcon />
          </Link>
          <CountBadge count={partner.unreadRequestsCount} />
        </StyledText>
      </StyledInfoWrapper>
    </KOLCardWrapper>
  );
};

export default PartnerCard;
