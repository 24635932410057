import { KYCStatuses } from '@/constants';
import { IGeographies } from './dataLists.model';
import { IGetListParamsBase } from './api.model';
import { IMainContact } from '@/models/sharedProfile.model';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';

export enum KOLsListEndpoints {
  GET_KOLS_LIST = '/admin/kols',
}

export enum KolStatuses {
  PENDING = 'pending',
  NOT_VERIFIED = 'not_verified',
  IN_VERIFICATION = 'in_verification',
  VERIFIED = 'verified',
  BLOCKED = 'blocked',
}

export interface ISocialChannelKOL {
  name: string;
  link: string;
  error?: string;
  lastUpdated?: string;
  followers?: number;
  price?: number;
  expectedPrice?: number;
  engagementRate?: number;
  richRate?: number;
  gradeScore?: string;
  influenceScore?: number;
  followerQualityScore?: number;
}

export interface IVerificationDTO {
  documentId: boolean;
  liveness: boolean;
  phoneVerification: boolean;
  proofOfResidency: boolean;
  sessionId: string | null;
  state: KYCStatuses | null;
  address: {
    country: string;
    city: string;
    address: string;
  };
  fullName?: string;
  nationality?: string;
  documentNumber?: string;
  title?: string;
  registration?: string;
  registrationNumber?: string;
}

export interface IInvitedBy {
  id: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  email: string;
}

export interface IProfileCommunity {
  value: string;
  id: number;
}

export interface IKOL {
  id?: string;
  firstName?: string;
  lastName?: string;
  alias?: string;
  formAddress?: string;
  address?: string;
  country?: string;
  city?: string;
  mainContact?: IMainContact;
  email?: string;
  mainCommunity?: IProfileCommunity;
  secondaryCommunity?: IProfileCommunity;
  mainGeography?: IGeographies;
  secondaryGeography?: IGeographies;
  socialChannel?: ISocialChannelKOL[];
  status?: string;
  KYC?: IVerificationDTO;
  created?: string;
  modified?: string;
  role?: string;
  affiliateCode?: string;
  linkClicks?: string;
  registrationsCompleted?: string;
  totalAffiliateCommissionAmount?: number;
  walletAddress?: string;
  walletCurrency?: CryptoCurrenciesEnum;
  walletNetwork?: WalletNetworksEnum;
  formatAddress?: string;
  invitedBy?: IInvitedBy;
  influenceDataReceivedAt?: string;
  newRequestCount?: string;
  unreadRequestsCount?: number;
  hasParticipations?: boolean;
}

export interface IChannelInvestment {
  investedTokens: number;
  followers: number;
  numberOfPosts: number;
  price: number;
  investedInCurrency: number;
}

export interface IKOLInvestment {
  alias: string;
  id: string;
  investedAmount: number;
  investedForMarketing: number;
  investedMoneyAmount: number;
  investedTokensAmount: number;
  investedTokensForMoney: number;
  investmentStatus: string;
  modified: string;
  projectId: string;
  socialChannelInvestment: IChannelInvestment[];
  title: string;
  tokensForMarketing: number;
}

export interface ISocialChannelInvestment {
  name: string;
  link?: string;
  followers: number;
  price: number;
  numberOfPosts: number;
  investedInCurrency: number;
  investedTokens: number;
}

export enum MarketingAffiliateCommissionType {
  Campaign = 'campaign',
  Participation = 'participation',
}

export interface MarketingAffiliateCommission {
  sk: string;
  type?: MarketingAffiliateCommissionType;
  recipientId?: string;
  invitedUserId?: string;
  invitedUserName?: string;
  invitedUserRole?: string;
  campaignId?: string;
  campaignTitle?: string;
  participationId?: string;
  participationSk?: string;
  campaignUsedBudget?: number;
  participationAmount?: number;
  affiliateCommission?: number;
}

// ============= DTO =============

export interface IGetKOLsListDTO extends IGetListParamsBase {
  search?: string;
  status?: KolStatuses;
}

export interface IGetKOLInvestmentsDTO extends IGetListParamsBase {
  kolId?: string;
}

export interface IKOLsDetailsDTO {
  alias?: string;
  formatAddress?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  mainContact?: IMainContact;
  walletAddress?: string;
  walletCurrency?: CryptoCurrenciesEnum;
  socialChannel?: ISocialChannelKOL[];
  mainCommunity?: IProfileCommunity;
  secondaryCommunity?: IProfileCommunity;
  mainGeography?: IGeographies;
  secondaryGeography?: IGeographies;
}

export interface IUpdateKOLsDetailsDTO {
  kol: IKOLsDetailsDTO;
  id: string;
}

export interface IBlockKOLUser {
  id?: string;
  reason?: string;
}

// ============== Response ==============

export interface IKOLsListResponse {
  items: IKOL[];
  hasMore: boolean;
}

export interface IKOLAffiliateResponse {
  items: MarketingAffiliateCommission[];
  hasMore: boolean;
}

export interface IKOLDetailsResponse {
  data: IKOL;
}
