// Globals
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { TextFileIcon, UploadIcon } from '@/icons';
import {
  IMAGE_MIME_TYPES,
  MAX_IMAGE_SIZE,
  PureImageUploader,
} from '../../index';
import {
  StyledUploadImagePlaceholder,
  ImageContainer,
  StyledDownloadButton,
  StyledCrossInCircleIcon,
  StyledDocumentWrapper,
} from './FileUploadStyles';
import { Spinner } from '@/ui';

export interface IFileUpload {
  isLoading?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  onChange?: (value: File | Blob | null) => void;
  value?: string;
  placeholder?: string;
  acceptance?: string;
  target?: string;
  fileMaxSize?: number;
  children: JSX.Element;
  withPreview?: boolean;
  maxCount?: number;
  showList?: boolean;
}

const FileUpload = ({
  value,
  onChange,
  target = 'blanc',
  acceptance = IMAGE_MIME_TYPES,
  fileMaxSize = MAX_IMAGE_SIZE,
  isLoading: loading,
  disabled,
  maxCount,
  showList,
  withPreview = true,
}: IFileUpload) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['common']);
  const handleRemove = () => !disabled && onChange && onChange(null);

  const handleChange = (file: File | Blob) => {
    onChange && onChange(file);
    setIsLoading(true);
  };

  useEffect(() => {
    !loading && setIsLoading(false);
  }, [loading]);

  if (isLoading && withPreview) {
    return <Spinner />;
  }

  return value && withPreview ? (
    <ImageContainer>
      <StyledDocumentWrapper>
        <StyledCrossInCircleIcon onClick={handleRemove} />
        <StyledDownloadButton href={value} target={target}>
          <TextFileIcon />
        </StyledDownloadButton>
      </StyledDocumentWrapper>
    </ImageContainer>
  ) : (
    <PureImageUploader
      imageMaxSize={fileMaxSize}
      maxCount={maxCount}
      showUploadList={showList}
      acceptance={acceptance}
      onChange={handleChange}
    >
      <StyledUploadImagePlaceholder>
        <UploadIcon />
        {t('file_upload_placeholder', { ns: 'common' })}
      </StyledUploadImagePlaceholder>
    </PureImageUploader>
  );
};

export default FileUpload;
