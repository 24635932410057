/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-ts-comment  */
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser as CognitoUserClass,
  CognitoUserSession,
  UserData,
} from 'amazon-cognito-identity-js';
import { CognitoIdentityCredentials, config } from 'aws-sdk';
import { env } from 'src/config/env';
import { userPool } from './UserPool';
import { normalizeCognitoAttributes } from './helpers';

const defaultRegion = 'eu-central-1';
const {
  COGNITO_IDENTITY_POOL_ID: identityPoolId,
  COGNITO_LOGIN_URI: cognitoLoginURI,
} = env;

class CognitoUserInstance {
  email;
  instance;

  constructor(email: string) {
    this.email = email;
    this.instance = new CognitoUserClass({
      Username: email,
      Pool: userPool,
    });
  }

  async refreshSession(
    refreshToken: string,
  ): Promise<CognitoUserSession | null> {
    const token = new CognitoRefreshToken({ RefreshToken: refreshToken });

    return new Promise((resolve, reject) => {
      this.instance.refreshSession(
        token,
        (err: Error | undefined, result: CognitoUserSession) =>
          err ? reject(err) : resolve(result),
      );
    });
  }

  async getSession() {
    return await new Promise((resolve, reject) => {
      this.instance.getSession(
        (error: null | Error, session: CognitoUserSession) => {
          if (error) {
            reject(error);
          } else {
            resolve(session);
          }
        },
      );
    });
  }

  async getUserData() {
    return await new Promise((resolve, reject) => {
      this.instance.getUserData(function (
        err: Error | undefined,
        userData: UserData | undefined,
      ) {
        if (err) {
          console.error(err);
          return reject(err);
        } else {
          return resolve(normalizeCognitoAttributes(userData?.UserAttributes));
        }
      });
    });
  }

  logOut() {
    this.instance.signOut();
  }

  async signIn(email: string, password: string) {
    return await new Promise((resolve, reject) => {
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      this.instance.authenticateUser(authDetails, {
        onSuccess: async (result) => {
          config.region = defaultRegion;

          const cognitoIdentityCredentials = new CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
            Logins: {
              [cognitoLoginURI]: result.getIdToken().getJwtToken(),
            },
          });
          config.credentials = cognitoIdentityCredentials;

          cognitoIdentityCredentials.refresh((error) => {
            if (error) {
              console.error(error);
            }
          });

          return resolve(result);
        },

        onFailure: (err) => {
          return reject(err);
        },
      });
    });
  }
}

export default CognitoUserInstance;
