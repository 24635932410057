import { IApiError } from '@/models/apiError.model';
import {
  IContractInfoResponse,
  IGetProjectsListDTO,
  IProject,
} from '@/models/projects.model';
import { MarketingAffiliateCommission } from '@/models/kolsList.model';
import { IUser } from '@/models/admin.model';

export const PROJECTS_SLICE_NAME = 'projects';

export interface ProjectsState {
  projectsDetails: IProject | null;
  affiliateData: {
    items: MarketingAffiliateCommission[] | null;
    hasMore: boolean;
  };
  projectsData: {
    items: IProject[] | null;
    hasMore: boolean;
  };
  serviceAgreementInfo: IContractInfoResponse | null;
  invitedUsers: {
    items: IUser[] | null;
    hasMore: boolean;
  };
  serviceAgreementPreview: string;
  projectsFilters: IGetProjectsListDTO;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: ProjectsState = {
  projectsData: {
    items: [],
    hasMore: true,
  },
  affiliateData: {
    items: [],
    hasMore: true,
  },
  invitedUsers: {
    items: [],
    hasMore: true,
  },
  projectsDetails: null,
  projectsFilters: {},
  serviceAgreementInfo: null,
  serviceAgreementPreview: '',
  isLoading: false,
  error: null,
};
