import { KYCStatuses } from '@/constants';
import {
  IInvitedBy,
  MarketingAffiliateCommissionType,
} from '@/models/kolsList.model';
import { IGetListParamsBase } from './api.model';
import { IMainContact } from '@/models/sharedProfile.model';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';

export enum PartnersListEndpoints {
  GET_PARTNERS_LIST = '/admin/partners',
}

export interface IVerificationDTO {
  documentId: boolean;
  liveness: boolean;
  phoneVerification: boolean;
  proofOfResidency: boolean;
  sessionId: string | null;
  state: KYCStatuses | null;
  address: {
    country: string;
    city: string;
    address: string;
  };
  fullName?: string;
  nationality?: string;
  documentNumber?: string;
  title?: string;
  registration?: string;
  registrationNumber?: string;
}

export interface IPartner {
  id: string;
  firstName?: string;
  lastName?: string;
  formAddress?: string;
  address?: string;
  country?: string;
  city?: string;
  created?: string;
  mainContact?: IMainContact;
  email: string;
  role: string;
  KYC: IVerificationDTO;
  invitedBy: IInvitedBy;
  status: string;
  newRequestCount: string;
  walletAddress?: string;
  walletCurrency?: CryptoCurrenciesEnum;
  walletNetwork?: WalletNetworksEnum;
  totalAffiliateCommissionAmount?: number;
  unreadRequestsCount: number;
}

export interface IPartnerAffiliateData {
  paymentStatus: string;
  sk: string;
  type?: MarketingAffiliateCommissionType;
  firstName: string;
  lastName: string;
  userRole: string;
  investedNetAmount: number;
  affiliateCommissionAmount: number;
  id: string;
  title: string;
  investedAmount: number;
  investedTokensAmount: number;
  created: string;
  modified: string;
  investmentStatus: string;
  investedForMarketing: number;
  tokensForMarketing: number;
  investedTokensForMoney: number;
  contractLink: string;
}

// ============= DTO =============

export interface IPartnersDetailsDTO {
  formatAddress?: string;
  address?: string;
  mainContact?: IMainContact;
  walletAddress?: string;
  walletCurrency?: CryptoCurrenciesEnum;
}

export interface IGetPartnersListDTO extends IGetListParamsBase {
  search?: string;
}

export interface IGetPartnerInvestmentsDTO extends IGetListParamsBase {
  partnerId?: string;
}

export interface IUpdatePartnersDetailsDTO {
  partner: IPartnersDetailsDTO;
  id: string;
}

export interface IBlockPartnerUser {
  id?: string;
  reason?: string;
}

// ============== Response ==============

export interface IPartnersListResponse {
  items: IPartner[];
  hasMore: boolean;
}

export interface IPartnerAffiliateResponse {
  items: IPartnerAffiliateData[];
  hasMore: boolean;
}

export interface IPartnerDetailsResponse {
  data: IPartner;
}
