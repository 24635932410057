import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { env } from 'src/config/env';

const userPoolId = env.COGNITO_USER_POOL_ID;
const clientId = env.COGNITO_CLIENT_ID;

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};

export const userPool = new CognitoUserPool(poolData);
