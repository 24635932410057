import { PayloadAction, createSlice, isRejected } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { AUTH_SLICE_NAME, AuthState, initialState } from './models';
import {
  AUTHORIZATION_TOKEN_STORAGE_KEY,
  ResponseStatusCodes,
} from '@/constants';
import { appCookiesStorage } from '@/utils';

export const slice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {
    setIsAuthorized(state, action: PayloadAction<boolean>) {
      state.isAuthorized = action.payload;
    },
    setUserEmail(state, action: PayloadAction<string>) {
      state.userEmail = action.payload;
    },
    setUserData(state, action) {
      state.user = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    resetAuthState() {
      return { ...initialState };
    },
    logOut() {
      appCookiesStorage.removeAll();
      localStorage.clear();
      return { ...initialState, isAuthorized: false, isLoading: false };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejected(), (state: AuthState, action) => {
      const { error } = action;
      if ((error as IApiError)?.status === ResponseStatusCodes.NOT_AUTHORIZED) {
        state.user = null;
        state.isAuthorized = false;
        state.authToken = null;
        appCookiesStorage.removeItem(AUTHORIZATION_TOKEN_STORAGE_KEY);
      }
    });
  },
});

export const {
  setIsAuthorized,
  setUserEmail,
  setIsLoading,
  setUserData,
  logOut,
} = slice.actions;

export default slice.reducer;
