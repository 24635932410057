import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { Button, Select } from '@/ui';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

export const StyledButton = styled(Button)`
  &&& {
    height: 2.25rem;
    background: ${COLORS.BRIGHT_ORANGE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledExportButton = styled(Button)`
  &&& {
    width: 6.25rem;
    height: 2.25rem;
    border: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    cursor: pointer;
    color: ${COLORS.LIQUORICE_BLACK};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    border-radius: 6px;
    background: var(--input-default-background, #fff);
    box-shadow: 0px 0px 1px 0px rgba(50, 50, 71, 0.2),
      0px 1px 2px 0px rgba(50, 50, 71, 0.08);
  }
`;

export const StyledBox = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledSelect = styled(Select)`
  &.ant-select {
    &-single {
      & .ant-select-selector {
        height: 2.25rem;
        border: 0;
        & .ant-select-selection-placeholder {
          ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
          margin: auto;
        }
        & .ant-select-selection-item {
          ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
          margin: auto;
        }
      }
      .ant-select-clear {
        background: ${COLORS.WHITE};
      }
    }
  }
  margin-bottom: 1rem;
  @media screen and ${DEVICES.LAPTOP_S} {
    margin-bottom: 0;
  }
`;
