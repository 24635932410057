import omit from 'lodash.omit';
import { ApiResponse, httpGet, httpPost } from './httpClient';
import {
  ILegalAgreementsDTO,
  IWithdrawalHistoryDTO,
  ILegalAgreementResponse,
  SharedEndpoints,
  IWithdrawalHistory,
  ISeedHunterCreditsListDTO,
  ISeedHunterCreditsListResponse,
  ICreditBonusDTO,
  ICreditHistory,
  IFundingRoundAffiliateDTO,
  IFundingRoundAffiliateResponse,
} from '@/models/sharedProfile.model';

class SharedProfileAPI {
  static getLegalAgreements(
    data: ILegalAgreementsDTO,
  ): ApiResponse<ILegalAgreementResponse[]> {
    return httpGet<ILegalAgreementResponse[]>(
      `${SharedEndpoints.ROOT}/${data?.role}/${encodeURI(
        data?.id ?? '',
      )}/action-logs`,
      { types: data.types },
    );
  }

  static getWithdrawalHistory(
    data: IWithdrawalHistoryDTO,
  ): ApiResponse<IWithdrawalHistory[]> {
    const { id, role } = data;
    return httpGet<IWithdrawalHistory[]>(
      `${SharedEndpoints.ROOT}/${role}/${encodeURI(
        id,
      )}/affiliate-withdrawal-history`,
    );
  }

  static getFundingRoundAffiliateList(
    data: IFundingRoundAffiliateDTO,
  ): ApiResponse<IFundingRoundAffiliateResponse> {
    return httpGet<IFundingRoundAffiliateResponse>(
      `${SharedEndpoints.ROOT}/${data?.role}/${encodeURI(
        data?.id ?? '',
      )}/funding-round-affiliate`,
      {
        ...data,
      },
    );
  }

  static getSeedHunterCreditsList(
    data: ISeedHunterCreditsListDTO,
  ): ApiResponse<ISeedHunterCreditsListResponse> {
    return httpGet<ISeedHunterCreditsListResponse>(
      `${SharedEndpoints.ROOT}/${data?.role}/${encodeURI(
        data?.id ?? '',
      )}/bonus-credit`,
      {
        ...data,
      },
    );
  }

  static postCreditBonus(data: ICreditBonusDTO) {
    return httpPost<ICreditBonusDTO, ICreditHistory>(
      `${SharedEndpoints.ROOT}/${data?.role}/${encodeURI(
        data?.id ?? '',
      )}/bonus-credit`,
      omit(data, ['id', 'role']),
    );
  }
}

export default SharedProfileAPI;
