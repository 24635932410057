// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Store
import { useAppDispatch } from '@/hooks';

// Modules
import {
  TLogInFormValues,
  setUserEmail,
  useGetFormValidationRules,
} from '@/modules/auth';

// Helpers
import { AuthFieldsNames } from '@/types';

// Components
import { Input, PasswordInput } from '@/ui';
import { Form, FormItem } from '@/components';
import { StyledButton, StyledTitle } from './LogInFormStyles';

interface ILogInForm {
  handleSubmit: (values: TLogInFormValues) => void;
  isLoading: boolean;
}

const LogInForm = ({ handleSubmit, isLoading }: ILogInForm) => {
  const { t } = useTranslation(['auth', 'common']);
  const dispatch = useAppDispatch();

  const validationRules = useGetFormValidationRules();

  const placeholder = t('common_placeholder_input', { ns: 'common' });

  const onValuesChange = (values: TLogInFormValues) => {
    if (values.email !== undefined) {
      dispatch(setUserEmail(values.email));
    }
  };

  return (
    <>
      <StyledTitle>{t('auth_log_in_title')}</StyledTitle>

      <Form onFinish={handleSubmit} onValuesChange={onValuesChange} noValidate>
        <FormItem
          name={AuthFieldsNames.EMAIL}
          rules={validationRules[AuthFieldsNames.EMAIL]}
          label={t('auth_email_label')}
        >
          <Input placeholder={placeholder} type="email" />
        </FormItem>

        <FormItem
          name={AuthFieldsNames.PASSWORD}
          label={t('auth_password_label')}
        >
          <PasswordInput placeholder={placeholder} />
        </FormItem>

        <StyledButton type="primary" htmlType="submit" loading={isLoading}>
          {t('auth_log_in')}
        </StyledButton>
      </Form>
    </>
  );
};

export default LogInForm;
