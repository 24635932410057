// Globals
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledFields,
  StyledFormItem,
  StyledFrom,
  StyledSelect,
  StyledInfoWrapper,
} from './EarlyAccessStyles';
import { StyledDatePicker } from '@/ui';
import { StyledPageWrapper } from '../Styled';
import { IFormInstance } from '@/components';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Modules
import { CampaignTabKeys } from '@/modules/Campaigns';

// Helpers
import { ICampaignFields } from '../../types';
import { useGetFormValidationRules } from '../../hooks';
import { getVerifiedKols, selectUsersOptions } from '@/modules/DataLists';
import { useAppDispatch, useAppState } from '@/hooks';
import { ROUTES_PATHS } from '@/router';

interface IEarlyAccess {
  campaign: ICampaign;
  handleChange?: (values: ICampaign, allValues: ICampaign) => void;
  formInstance?: IFormInstance;
  disabled: boolean;
}

const EarlyAccess = ({
  campaign,
  handleChange,
  formInstance,
  disabled,
}: IEarlyAccess) => {
  const { t } = useTranslation(['campaigns']);
  const kolsOptions = useAppState(selectUsersOptions) ?? [];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const validationRules = useGetFormValidationRules();
  const defaultPublicDate = dayjs(campaign.marketingStartDate).subtract(
    48,
    'hours',
  );

  useEffect(() => {
    dispatch(getVerifiedKols());
  }, []);

  useEffect(() => {
    // Redirect to default tab if Early access not enabled:
    if (!campaign?.earlyAccess && campaign?.id) {
      navigate(
        `${ROUTES_PATHS.CAMPAIGNS}/${encodeURIComponent(campaign?.id)}/${
          CampaignTabKeys.CAMPAIGN
        }?tab=campaign`,
      );
    }
  }, [campaign]);

  if (!campaign || !Object.keys(campaign ?? {}).length) {
    return <Skeleton />;
  }

  return (
    <StyledPageWrapper>
      <StyledFrom
        form={formInstance}
        onValuesChange={handleChange}
        initialValues={campaign}
        disabled={disabled}
      >
        <StyledInfoWrapper $isColumn>
          <StyledFormItem
            name={ICampaignFields.PUBLIC_DATE}
            rules={validationRules[ICampaignFields.PUBLIC_DATE]}
            label={t('campaigns_details_public_date')}
          >
            <StyledDatePicker
              allowClear={false}
              showTime={true}
              defaultValue={defaultPublicDate}
              disabledDate={(current) => {
                return current && current < dayjs(dayjs()).add(2, 'weeks');
              }}
            />
          </StyledFormItem>

          <StyledFields>
            <StyledFormItem
              name={ICampaignFields.KOLS}
              rules={validationRules[ICampaignFields.PUBLIC_DATE]}
              label={t('campaigns_details_kols')}
            >
              <StyledSelect
                options={kolsOptions}
                showSearch
                mode={'multiple'}
              />
            </StyledFormItem>
          </StyledFields>
        </StyledInfoWrapper>
      </StyledFrom>
    </StyledPageWrapper>
  );
};

export default EarlyAccess;
