import { SocialChannels } from '@/models/socialChannel.model';

export const sortSocialChannels = <T>(
  arr: T[] | string[],
  key?: keyof T,
): T[] => {
  const channelsToSort = [...arr];
  const order = [
    SocialChannels.TWITTER,
    SocialChannels.YOUTUBE,
    SocialChannels.INSTAGRAM,
    SocialChannels.FACEBOOK,
    SocialChannels.TELEGRAM,
    SocialChannels.LINKEDIN,
    SocialChannels.REDDIT,
    SocialChannels.TIKTOK,
  ];

  if (key !== undefined && typeof key === 'string') {
    (channelsToSort as T[])?.sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (key in a && key in b) {
        return (
          order.indexOf(a[key] as unknown as SocialChannels) -
          order.indexOf(b[key] as unknown as SocialChannels)
        );
      } else {
        throw new Error(`Field '${key}' does not exist in objects.`);
      }
    });
  } else {
    (channelsToSort as string[]).sort(
      (a, b) =>
        order.indexOf(a as SocialChannels) - order.indexOf(b as SocialChannels),
    );
  }

  return channelsToSort as T[];
};
