import omit from 'lodash.omit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { PROJECTS_SLICE_NAME } from './models';
import { ProjectsAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  IContractInfoResponse,
  IContractInfoPreviewResponse,
  IGetContractInfoDTO,
  IGetProjectsListDTO,
  IProject,
  IProjectDetailsResponse,
  IProjectsListResponse,
  IUpdateProjectDetailsDTO,
  IGetProjectAffiliateResponse,
  IGetProjectAffiliateDTO,
  ICreateProjectWalletDTO,
  ICreateProjectWalletResponse,
  IContractInfoDTO,
} from '@/models/projects.model';
import { omitProjectFields } from '../constants/api';
import { KOLS_LIST_SLICE_NAME } from '@/modules/KolsList';
import {
  IGetInvitedUsersDTO,
  IGetInvitedUsersResponse,
} from '@/models/sharedProfile.model';

export const getProjectsList = createAsyncThunk<
  IProjectsListResponse,
  IGetProjectsListDTO,
  { serializedErrorType: IApiError }
>(
  `${PROJECTS_SLICE_NAME}/getProjectsList`,
  async (data: IGetProjectsListDTO) => {
    const response = await ProjectsAPI.getProjectsList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getProjectDetails = createAsyncThunk<
  IProjectDetailsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${PROJECTS_SLICE_NAME}/getProjectDetails`,
  async (id: string) => {
    const response = await ProjectsAPI.getProjectDetails(id);
    return response;
  },
  { serializeError: formatApiError },
);

export const updateProjectDetails = createAsyncThunk<
  IProject,
  IUpdateProjectDetailsDTO,
  { serializedErrorType: IApiError }
>(
  `${PROJECTS_SLICE_NAME}/updateProjectDetails`,
  async (data) => {
    const omittedData = omit(data, omitProjectFields);
    const response = await ProjectsAPI.updateProjectDetails(omittedData);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getContractInfo = createAsyncThunk<
  IContractInfoResponse,
  IGetContractInfoDTO,
  { serializedErrorType: IApiError }
>(
  `${PROJECTS_SLICE_NAME}/getContractInfo`,
  async (data: IGetContractInfoDTO) => {
    const response = await ProjectsAPI.getContractInfo(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateProjectContract = createAsyncThunk<
  IContractInfoResponse,
  IContractInfoDTO,
  {
    serializedErrorType: IApiError;
  }
>(
  `${PROJECTS_SLICE_NAME}/updateProjectContract`,
  async (data) => {
    const response = await ProjectsAPI.updateProjectContract(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getContractPreviewUrl = createAsyncThunk<
  IContractInfoPreviewResponse,
  IGetContractInfoDTO,
  { serializedErrorType: IApiError }
>(
  `${PROJECTS_SLICE_NAME}/getContractPreviewUrl`,
  async (data: IGetContractInfoDTO) => {
    const response = await ProjectsAPI.getContractPreviewUrl(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getProjectAffiliateData = createAsyncThunk<
  IGetProjectAffiliateResponse,
  IGetProjectAffiliateDTO,
  { serializedErrorType: IApiError }
>(
  `${KOLS_LIST_SLICE_NAME}/getProjectAffiliateData`,
  async (data) => {
    const response = await ProjectsAPI.getProjectAffiliateData({
      ...data,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const createProjectWallet = createAsyncThunk<
  ICreateProjectWalletResponse,
  ICreateProjectWalletDTO,
  { serializedErrorType: IApiError }
>(
  `${PROJECTS_SLICE_NAME}/createProjectWallet`,
  async (data) => {
    const response = await ProjectsAPI.createProjectWallet(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getProjectInvitedUsers = createAsyncThunk<
  IGetInvitedUsersResponse,
  IGetInvitedUsersDTO,
  { serializedErrorType: IApiError }
>(
  `${PROJECTS_SLICE_NAME}/getInvitedUsers`,
  async (data) => {
    const response = await ProjectsAPI.getInvitedUsers({ ...data, limit: 10 });
    return response.data;
  },
  { serializeError: formatApiError },
);
