// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

// Components
import {
  StyledButton,
  StyledArrowRightIcon,
  StyledStatusWrapper,
  StyledStatus,
} from './ProjectDetailsContainerStyles';
import { Title, StyledPageWrapper } from '@/ui';
import {
  StatusChangeBlock,
  Tabs,
  showSuccessToast,
  useForm,
} from '@/components';
import { ProjectInformation, CompanyInfo } from '../../components';
import ContractInfoContainer from '../ContractInformationContainer/ContractInformationContainer';

// Modules
import { useGetRequestCount } from '@/modules/Requests';
import {
  AffiliateWithdrawalHistoryContainer,
  LegalAgreementsContainer,
  getNormalizedLink,
  getStatusesConfig,
} from '@/modules/SharedProfile';
import { selectCommunity, selectGeography } from '@/modules/DataLists';
import {
  AffiliateFundingContainer,
  Affiliate,
  InvitedUsers,
} from '@/modules/SharedAffiliate';
import { getLogs } from '@/modules/Logs';
import { usePermissions } from '@/modules/Permissions';
import { ProofOfDeliveryContainer } from '@/modules/MarketingDelivery';

// Models
import { IProject } from '@/models/projects.model';
import { RequestersRoles } from '@/models/requests.model';
import { LegalAgreementRoles } from '@/models/sharedProfile.model';
import { LogEntities } from '@/models/logs.model';
import { AdminActionsEnum } from '@/models/adminsList.model';

// Hooks | Helpers
import { useAppDispatch, useAppState, useBoolean, useTabs } from '@/hooks';
import { ROUTES_PATHS } from '@/router';
import {
  checkFieldValidation,
  getByValue,
  getUserId,
  normalizedSocialChannels,
} from '@/utils';
import { ProjectTabKeys, projectTabsConfig } from '../../constants';
import { resetProjectsState } from '../../feature/slice';
import {
  getProjectAffiliateData,
  getProjectDetails,
  getProjectInvitedUsers,
  updateProjectDetails,
} from '../../feature/actionCreators';
import { projectsSliceSelector } from '../../feature/selectors';
import { statusesConfigs } from '../../constants/common';
import { ProjectFieldsNames, ProjectInformationFieldsNames } from '../../types';

const ProjectDetailsContainer = () => {
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, section } = useParams();
  const [form] = useForm();
  const community = useAppState(selectCommunity);
  const geography = useAppState(selectGeography);
  const [permissionToEdit] = usePermissions(AdminActionsEnum.WRITE_PROJECT);
  const { projectsDetails, affiliateData, invitedUsers, isLoading } =
    useAppState(projectsSliceSelector);
  const count = useGetRequestCount(
    RequestersRoles.PROJECT,
    projectsDetails?.id,
  );
  const { value: isEditActive, toggle: toggleIsEditActive } = useBoolean();
  const getRedirectUrl = (key: string) =>
    `${ROUTES_PATHS.PROJECTS_LIST}/${encodeURIComponent(id ?? '')}/${key}`;
  const { onChange } = useTabs(ProjectTabKeys.COMPANY_INFO, getRedirectUrl);

  const handleChangeStatus = useCallback(
    async (description: string, status?: string) => {
      await dispatch(
        updateProjectDetails({
          reason: description,
          status: status,
          id: getUserId(projectsDetails?.id ?? ''),
        }),
      ).unwrap();
      dispatch(
        getLogs({
          entity: LogEntities.PROJECT,
          id: getUserId(projectsDetails?.id ?? ''),
        }),
      );
    },
    [dispatch, projectsDetails],
  );

  const getNextAffiliateData = useCallback(
    debounce(() => {
      if (isLoading || !affiliateData?.hasMore) return;
      const lastItem = affiliateData?.items?.at(-1);
      dispatch(
        getProjectAffiliateData({
          startSk: lastItem?.sk,
          projectId: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, affiliateData?.hasMore, dispatch],
  );

  const getNextInvitedUsers = useCallback(
    debounce(() => {
      if (isLoading || !invitedUsers?.hasMore) return;
      const lastItem = invitedUsers?.items?.at(-1);
      dispatch(
        getProjectInvitedUsers({
          startId: lastItem?.id as string,
          userId: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, invitedUsers?.hasMore, dispatch],
  );

  const handleSubmit = useCallback(
    async (values: IProject) => {
      await checkFieldValidation(values, form);
      const socialChannelError = form.getFieldError(
        ProjectInformationFieldsNames.SOCIAL_CHANNEL,
      );
      try {
        if (socialChannelError.length > 0) {
          throw t('projects_general_error_toast');
        }
        await dispatch(
          updateProjectDetails({
            ...values,
            website: getNormalizedLink(
              values?.[ProjectFieldsNames.WEBSITE] ?? '',
            ),
            mainCommunity: getByValue(
              community ?? [],
              values?.[ProjectFieldsNames.MAIN_COMMUNITY],
            ),
            mainGeography: getByValue(
              geography ?? [],
              values?.[ProjectFieldsNames.PRIMARY_GEOGRAPHY],
            ),
            secondaryCommunity: getByValue(
              community ?? [],
              values?.[ProjectFieldsNames.SECONDARY_COMMUNITY],
            ),
            secondaryGeography: getByValue(
              geography ?? [],
              values?.[ProjectFieldsNames.SECONDARY_GEOGRAPHY],
            ),
            [ProjectInformationFieldsNames.SOCIAL_CHANNEL]:
              normalizedSocialChannels(values.socialChannel),
            id: getUserId(projectsDetails?.id ?? ''),
          }),
        ).unwrap();
        toggleIsEditActive();
        showSuccessToast({
          message: t('projects_general_success_toast'),
        });
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [dispatch, form, projectsDetails],
  );

  const navigateToProjectsList = () => {
    navigate(ROUTES_PATHS.PROJECTS_LIST);
  };

  const getSectionContent = (section?: string) => {
    switch (section) {
      case ProjectTabKeys.COMPANY_INFO:
        return (
          <CompanyInfo
            projectDetails={projectsDetails}
            isLoading={isLoading}
            permissionToEdit={permissionToEdit}
          />
        );
      case ProjectTabKeys.PROJECT_INFORMATION:
        return (
          <ProjectInformation
            handleSubmit={handleSubmit}
            projectDetails={projectsDetails}
            permissionToEdit={permissionToEdit}
            formInstance={form}
            isEditActive={isEditActive}
            toggleIsEditActive={toggleIsEditActive}
          />
        );
      case ProjectTabKeys.SERVICE_AGREEMENT_DETAILS:
        return <ContractInfoContainer />;
      case ProjectTabKeys.AFFILIATE:
        return (
          <>
            <Affiliate
              affiliateData={affiliateData}
              isLoading={isLoading}
              getNextAffiliateData={getNextAffiliateData}
              overallBalance={projectsDetails?.totalAffiliateCommissionAmount}
            />
            <InvitedUsers
              invitedUsers={invitedUsers?.items}
              getNextInvitedUsers={getNextInvitedUsers}
              isLoading={isLoading}
              hasMore={invitedUsers?.hasMore}
            />
            <AffiliateWithdrawalHistoryContainer
              id={getUserId(id ?? '')}
              role={LegalAgreementRoles.PROJECTS}
            />
          </>
        );
      case ProjectTabKeys.AFFILIATE_FUNDING:
        return (
          <>
            <AffiliateFundingContainer
              id={getUserId(id ?? '')}
              role={LegalAgreementRoles.PROJECTS}
              overallBalance={projectsDetails?.totalAffiliateCommissionAmount}
            />
            <AffiliateWithdrawalHistoryContainer
              id={getUserId(id ?? '')}
              role={LegalAgreementRoles.PROJECTS}
            />
          </>
        );
      case ProjectTabKeys.PROOF_OF_DELIVERY:
        return <ProofOfDeliveryContainer />;
      case ProjectTabKeys.LEGAL_AGREEMENTS:
        return <LegalAgreementsContainer role={LegalAgreementRoles.PROJECTS} />;
    }
  };

  useEffect(
    () => (): void => {
      dispatch(resetProjectsState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (id) {
      dispatch(getProjectDetails(getUserId(id)));
      getNextInvitedUsers();
    }
  }, [id, projectsDetails?.status]);

  return (
    <StyledPageWrapper>
      <StyledStatusWrapper>
        <Title $type="h3">{projectsDetails?.title}</Title>
        <StyledStatus>
          <Title $type="h2">{t('projects_general_info_status')}</Title>
          <StatusChangeBlock
            status={projectsDetails?.status}
            handleRequest={handleChangeStatus}
            isEditable={permissionToEdit}
            statusesConfigs={getStatusesConfig(
              projectsDetails?.status ?? '',
              statusesConfigs,
            )}
            withStatus
          />
        </StyledStatus>
      </StyledStatusWrapper>
      <StyledButton type="ghost" onClick={navigateToProjectsList}>
        <StyledArrowRightIcon />
        {t('projects_list_details_go_back')}
      </StyledButton>
      <Tabs
        count={count}
        sections={projectTabsConfig}
        activeTab={section}
        onChange={onChange}
        withCount
      />
      {getSectionContent(section)}
    </StyledPageWrapper>
  );
};

export default ProjectDetailsContainer;
