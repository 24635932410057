import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { AUTH_SLICE_NAME, AuthState } from './models';

export const authSliceSelector = (state: RootState): AuthState =>
  state[AUTH_SLICE_NAME];

export const selectUserEmail = createSelector(
  authSliceSelector,
  (state: AuthState) => state.userEmail,
);

export const selectAuthIsLoading = createSelector(
  authSliceSelector,
  (state: AuthState) => state.isLoading,
);

export const selectIsAuthorized = createSelector(
  authSliceSelector,
  (state: AuthState) => state.isAuthorized,
);

export const selectUserData = createSelector(
  authSliceSelector,
  (state: AuthState) => state.user,
);
