import { IApiError } from '@/models/apiError.model';
import {
  IFundingPayoutListItem,
  IFundingPayoutDetails,
  IPayoutDetails,
  IPayoutList,
} from '@/models/payout.model';
import { FundingRoundParticipantsReportResponse } from '@/models/reports.model';

export const PAYOUT_SLICE_NAME = 'payout';

export interface PayoutState {
  payoutList: IPayoutList[] | null;
  fundingPayoutList: IFundingPayoutListItem[] | null;
  fundingRefundList: IFundingPayoutListItem[] | null;
  fundingPayoutDetailsData: IFundingPayoutDetails | null;
  fundingRefundDetailsData: FundingRoundParticipantsReportResponse | null;
  payoutListDetailsData: {
    items: IPayoutDetails[] | null;
    hasMore: boolean;
  };
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: PayoutState = {
  payoutList: null,
  fundingPayoutList: null,
  fundingPayoutDetailsData: null,
  fundingRefundDetailsData: null,
  fundingRefundList: null,
  payoutListDetailsData: {
    items: [],
    hasMore: true,
  },
  isLoading: false,
  error: null,
};
