// Globals
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'antd/lib/form/Form';

// Components
import { useForm } from 'antd/es/form/Form';
import {
  StyledInfluencerWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledForm,
  StyledButtonWrapper,
  StyledButton,
  StyledGetButton,
  StyledFormItem,
  StyledCommunityWrapper,
} from './InfluencerDataStyles';
import InfluencerSocialChannel from '../InfluencerSocialChannel/InfluencerSocialChannel';
import { showSuccessToast, showErrorToast } from '@/components';
import { Spinner, StyledSelect } from '@/ui';
import AcceptModal from './AcceptModal';

// Modules
import { SocialChannelsSelectBlock } from '@/modules/SharedProfile';
import {
  selectCommunity,
  selectGeography,
} from '@/modules/DataLists/feature/selectors';
import { DesktopOnly } from '@/modules/Adaptive';
import { domainValidation } from '@/modules/SharedProfile/helpers';

// Models
import { IKOL } from '@/models/kolsList.model';
import { ISocialChannel } from '@/models/projects.model';
import { UserRoles } from '@/models/admin.model';
import { SocialChannels } from '@/models/socialChannel.model';

// Hooks | helpers
import {
  checkFieldValidation,
  getByValue,
  getCommunityOptions,
  getUserId,
  sortSocialChannels,
} from '@/utils';
import { useAppDispatch, useAppState, useModal } from '@/hooks';
import { getKOLSocialChannels, updateKOL } from '../../feature/actionCreators';
import { AccountStatuses } from '@/constants';
import {
  InfluencerDataFieldsNames,
  normalizedKOLChannels,
} from '../../helpers';
import {
  selectInfluencerDataIsLoading,
  selectInfluencerDataError,
} from '../../feature/selectors';
import { resetErrors } from '../../feature/slice';

interface IInfluencerData {
  kolDetails: IKOL | null;
}

const InfluencerData = ({ kolDetails }: IInfluencerData) => {
  const { t } = useTranslation(['kolsList']);
  const { isOpened, openModal, closeModal } = useModal();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const community = useAppState(selectCommunity);
  const geography = useAppState(selectGeography);
  const influenceIsLoading = useAppState(selectInfluencerDataIsLoading);
  const influenceErrors = useAppState(selectInfluencerDataError);
  const isDisabled =
    kolDetails?.status === AccountStatuses.BLOCKED ||
    kolDetails?.hasParticipations;
  const mainCommunity = useWatch(
    InfluencerDataFieldsNames.MAIN_COMMUNITY,
    form,
  );
  const secondaryCommunity = useWatch(
    InfluencerDataFieldsNames.SECONDARY_COMMUNITY,
    form,
  );
  const mainGeography = useWatch(
    InfluencerDataFieldsNames.PRIMARY_GEOGRAPHY,
    form,
  );
  const secondaryGeography = useWatch(
    InfluencerDataFieldsNames.SECONDARY_GEOGRAPHY,
    form,
  );

  const mappedSocialChannels = useMemo(() => {
    return sortSocialChannels(kolDetails?.socialChannel ?? [])?.map(
      (channel) => {
        const warning = influenceErrors?.[channel.name];
        return (
          <InfluencerSocialChannel
            key={channel.name}
            channel={channel}
            warning={warning}
          />
        );
      },
    );
  }, [kolDetails?.socialChannel, influenceErrors]);

  const getInfluencerData = async () => {
    await dispatch(
      getKOLSocialChannels(getUserId(kolDetails?.id ?? '')),
    ).unwrap();
  };

  const handleSubmit = useCallback(
    async (values: IKOL) => {
      await checkFieldValidation(values, form);

      const isDomainIncorrect = values.socialChannel?.some(
        (value: ISocialChannel) => {
          return !domainValidation(value.name ?? '', value.link ?? '');
        },
      );

      if (isDomainIncorrect) {
        return showErrorToast({
          message: t('kols_general_domain_channels_error_toast'),
        });
      }

      try {
        dispatch(resetErrors());
        kolDetails &&
          (await dispatch(
            updateKOL({
              kol: {
                ...values,
                mainCommunity: getByValue(
                  community ?? [],
                  values?.[InfluencerDataFieldsNames.MAIN_COMMUNITY],
                ),
                secondaryCommunity: getByValue(
                  community ?? [],
                  values?.[InfluencerDataFieldsNames.SECONDARY_COMMUNITY],
                ),
                mainGeography: getByValue(
                  geography ?? [],
                  values?.[InfluencerDataFieldsNames.PRIMARY_GEOGRAPHY],
                ),
                secondaryGeography: getByValue(
                  geography ?? [],
                  values?.[InfluencerDataFieldsNames.SECONDARY_GEOGRAPHY],
                ),
                socialChannel: normalizedKOLChannels(
                  values.socialChannel ?? [],
                ),
              },
              id: getUserId(kolDetails.id ?? ''),
            }),
          ).unwrap());

        showSuccessToast({
          message: t('kols_influencer_data_channels_success_toast'),
        });
      } catch (e) {
        form.setFieldsValue(kolDetails);
      }
    },
    [form, dispatch, kolDetails, community],
  );

  useEffect(() => {
    form.setFieldsValue({
      ...kolDetails,
      socialChannel: normalizedKOLChannels(kolDetails?.socialChannel),
    });
  }, [form, kolDetails]);

  return (
    <>
      <StyledInfluencerWrapper>
        <StyledButtonWrapper>
          <StyledGetButton
            type="ghost"
            onClick={getInfluencerData}
            disabled={influenceIsLoading}
          >
            {influenceIsLoading ? (
              <Spinner />
            ) : (
              <StyledTitle>
                {t('kols_influencer_get_influencer_data')}
              </StyledTitle>
            )}
          </StyledGetButton>
        </StyledButtonWrapper>
        <DesktopOnly>
          <StyledInfoWrapper>
            <StyledTitle>
              {t('kols_influencer_data_social_channel_title')}
            </StyledTitle>
            <StyledTitle>{t('kols_influencer_data_last_updated')}</StyledTitle>
            <StyledTitle>
              {t('kols_influencer_data_followers_title')}
            </StyledTitle>
            <StyledTitle>
              {t('kols_influencer_data_engagement_title')}
            </StyledTitle>
            <StyledTitle>{t('kols_influencer_data_rich_title')}</StyledTitle>
            <StyledTitle>
              {t('kols_influencer_data_grade_score_title')}
            </StyledTitle>
            <StyledTitle>
              {t('kols_influencer_data_influencer_score_title')}
            </StyledTitle>
            <StyledTitle>
              {t('kols_influencer_data_followers_quality_title')}
            </StyledTitle>
          </StyledInfoWrapper>
        </DesktopOnly>
        {mappedSocialChannels}
      </StyledInfluencerWrapper>
      <StyledForm disabled={isDisabled} onFinish={handleSubmit} form={form}>
        <StyledCommunityWrapper>
          <StyledFormItem
            name={InfluencerDataFieldsNames.MAIN_COMMUNITY}
            label={t('kols_influencer_data_main_community_title')}
          >
            <StyledSelect
              options={getCommunityOptions(community ?? [], secondaryCommunity)}
              placeholder={t('kols_influencer_data_main_community_title')}
            />
          </StyledFormItem>
          <StyledFormItem
            name={InfluencerDataFieldsNames.SECONDARY_COMMUNITY}
            label={t('kols_influencer_data_secondary_community_title')}
          >
            <StyledSelect
              options={getCommunityOptions(community ?? [], mainCommunity)}
              placeholder={t('kols_influencer_data_secondary_community_title')}
            />
          </StyledFormItem>
          <StyledFormItem
            name={InfluencerDataFieldsNames.PRIMARY_GEOGRAPHY}
            label={t('milestones_primary_geography_label', {
              ns: 'projectsList',
            })}
          >
            <StyledSelect
              options={getCommunityOptions(geography ?? [], secondaryGeography)}
              placeholder={t('milestones_primary_geography_placeholder', {
                ns: 'projectsList',
              })}
              showSearch
            />
          </StyledFormItem>
          <StyledFormItem
            name={InfluencerDataFieldsNames.SECONDARY_GEOGRAPHY}
            label={t('milestones_secondary_geography_label', {
              ns: 'projectsList',
            })}
          >
            <StyledSelect
              options={getCommunityOptions(geography ?? [], mainGeography)}
              placeholder={t('milestones_secondary_geography_placeholder', {
                ns: 'projectsList',
              })}
              showSearch
            />
          </StyledFormItem>
        </StyledCommunityWrapper>
        <StyledInfoWrapper>
          <SocialChannelsSelectBlock
            formInstance={form}
            data={kolDetails}
            isEditActive={true}
            disabled={isDisabled}
            role={kolDetails?.role as UserRoles}
            requiredSocials={[SocialChannels.TWITTER]}
          />
        </StyledInfoWrapper>
        <StyledButtonWrapper>
          <StyledButton
            disabled={isDisabled}
            type="primary"
            onClick={openModal}
          >
            {t('edit_save')}
          </StyledButton>
        </StyledButtonWrapper>
        <AcceptModal
          formInstance={form}
          isOpened={isOpened}
          handleClose={closeModal}
        />
      </StyledForm>
    </>
  );
};

export default InfluencerData;
