// Globals
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';

// Components
import { StyledInfo } from './CompanyInfoBlockStyles';
import CompanyInfoDetailsBlock from '../CompanyInfoDetailsBlock/CompanyInfoDetailsBlock';
import CompanyInfoEditBlock from '../CompanyInfoEditBlock/CompanyInfoEditBlock';
import { EditButton, showErrorToast, showSuccessToast } from '@/components';

// Models
import { IProject } from '@/models/projects.model';
import { LogEntities } from '@/models/logs.model';

// Modules
import { DesktopOnly, MobileOnly } from '@/modules/Adaptive';
import { getLogs } from '@/modules/Logs';

// Hooks | Utils
import { useAppDispatch, useBoolean } from '@/hooks';
import { checkFieldValidation, getUserId } from '@/utils';
import { isProjectDisabled } from '../../helpers';
import { updateProjectDetails } from '../../feature/actionCreators';
import { ProjectInfoDTO } from '../../dtos';

interface ICompanyInfoBlock {
  projectDetails: IProject | null;
  permissionToEdit?: boolean;
}

const CompanyInfoBlock = ({
  projectDetails,
  permissionToEdit,
}: ICompanyInfoBlock) => {
  const { value: isEditActive, toggle: toggleIsEditActive } = useBoolean();
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const [form] = useForm();

  const handleSubmit = useCallback(
    async (values: IProject) => {
      await checkFieldValidation(values, form);
      const projectDTO = new ProjectInfoDTO(values, projectDetails as IProject);
      try {
        projectDetails &&
          (await dispatch(
            updateProjectDetails({
              ...projectDTO,
              id: getUserId(projectDetails.id ?? ''),
            }),
          ).unwrap());
        toggleIsEditActive();
        dispatch(
          getLogs({
            entity: LogEntities.PROJECT,
            id: getUserId(projectDetails?.id ?? ''),
          }),
        );
        showSuccessToast({
          message: t('projects_general_success_toast'),
        });
      } catch (e: unknown) {
        showErrorToast({
          message: t('projects_general_error_toast'),
        });
      }
    },
    [form, dispatch, projectDetails],
  );

  return (
    <>
      <StyledInfo>
        {isEditActive ? (
          <CompanyInfoEditBlock
            projectDetails={projectDetails}
            formInstance={form}
            handleSubmit={handleSubmit}
          />
        ) : (
          <CompanyInfoDetailsBlock projectDetails={projectDetails} />
        )}
      </StyledInfo>
      {permissionToEdit && (
        <>
          <StyledInfo>
            <DesktopOnly>
              <EditButton
                isEditActive={isEditActive}
                setIsEditActive={toggleIsEditActive}
                isVisible={!isProjectDisabled(projectDetails?.status)}
              />
            </DesktopOnly>
          </StyledInfo>
          <MobileOnly>
            <EditButton
              isEditActive={isEditActive}
              setIsEditActive={toggleIsEditActive}
              position={'1rem'}
              isVisible={!isProjectDisabled(projectDetails?.status)}
            />
          </MobileOnly>
        </>
      )}
    </>
  );
};

export default CompanyInfoBlock;
