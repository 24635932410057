export enum UploadersEndpoints {
  GET_UPLOAD_URL = '/projects/presigned-url',
  PRESIGNED_URL = '/presigned-url',
  CAMPAIGNS = '/admin/projects/campaigns',
  FUNDING_ROUND_UPLOAD_URL = '/admin/projects/funding-rounds',
}

export interface IGetUploadUrlResponse {
  cloudfrontUrl: string;
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields?: any;
}

export interface IGetUploadUrlDTO {
  fileType?: string;
  targetId?: string;
  key?: string;
}

export interface IFileUploadDTO extends IGetUploadUrlDTO {
  value: File;
}
