// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  KOLsListWrapper,
  StyledInfoTitles,
  StyledTitles,
} from './KOLsListStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import KOLCard from '../KOLCard/KOLCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IKOL } from '@/models/kolsList.model';

// Hooks
import { useInfiniteScroll } from '@/hooks';

type TKOLsListProps = {
  kols: {
    items: IKOL[] | null;
    hasMore: boolean;
  };
  getNextProjects: () => void;
  isLoading: boolean;
};

const KOLsList = ({ kols, getNextProjects, isLoading }: TKOLsListProps) => {
  const { t } = useTranslation(['kolsList']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedKolsList = useMemo(() => {
    return kols?.items?.map((kolItem) => {
      return <KOLCard kol={kolItem} key={kolItem.id} />;
    });
  }, [kols?.items]);

  useInfiniteScroll({
    callback: getNextProjects,
    ref: loaderRef,
    isLoading,
    hasMore: kols?.hasMore,
  });

  return (
    <KOLsListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('kols_list_alias_title')}</StyledTitles>
          <StyledTitles>{t('kols_list_registration_date_title')}</StyledTitles>
          <StyledTitles>{t('kols_list_contact_title')}</StyledTitles>
          <StyledTitles>{t('kols_list_community_title')}</StyledTitles>
          <StyledTitles>{t('kols_list_geography_title')}</StyledTitles>
          <StyledTitles>{t('kols_list_social_channels_title')}</StyledTitles>
          <StyledTitles>{t('kols_list_social_status_title')}</StyledTitles>
          <StyledTitles>{t('kols_list_details')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedKolsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </KOLsListWrapper>
  );
};

export default KOLsList;
