import styled from 'styled-components';
import { BlockLayout, Collapse } from '@/ui';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledWrapper = styled(BlockLayout)`
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 1.5rem;
  margin-top: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1;
  padding: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    max-width: 25rem;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
`;

export const StyledText = styled.div<{ $isColored?: boolean }>`
  ${pickThemeFontStyles('14', '18', 'NORMAL')};
  word-break: break-word;
  color: ${({ $isColored }) =>
    $isColored ? COLORS.GRAY : COLORS.CORN_FLOWER_BLUE};
`;

export const StyledCollapse = styled(Collapse)`
  &&& {
    margin-top: 1rem;
    background: ${COLORS.WHITE}!important;
    .ant-collapse-item {
      padding: 0 0.8rem;
    }
    .ant-collapse-content-box {
      padding: 1rem 0;
    }
    .ant-collapse-header {
      padding-bottom: 0 !important;
      height: 2rem;
    }
  }
`;
