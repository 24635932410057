// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ICognitoUserSessionData } from 'amazon-cognito-identity-js';
import { ROUTES_PATHS } from '@/router';

// Hooks
import { useAppDispatch, useAppState, useCognito } from '@/hooks';

// Modules
import {
  authSliceSelector,
  getErrorMessage,
  LogInForm,
  setIsAuthorized,
  setUserData,
  TLogInFormValues,
} from '@/modules/auth';

// Models
import { IUser, UserRoles } from '@/models/admin.model';

// Helpers
import {
  AUTHORIZATION_TOKEN_STORAGE_KEY,
  CURRENT_USER_EMAIL_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  RESPONSE_ERRORS,
} from '@/constants';
import { appCookiesStorage } from '@/utils';

// Components
import {
  StyledFormWrapper,
  StyledLayout,
  StyledPageWrapper,
} from './LoginContainerStyles';
import { showErrorToast } from '@/components';

const LogInContainer = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, isAuthorized } = useAppState(authSliceSelector);
  const { cognitoUser } = useCognito();

  const login = async (values: TLogInFormValues) => {
    try {
      const result = (await cognitoUser?.signIn(
        values.email,
        values.password,
      )) as ICognitoUserSessionData;
      const userData = await cognitoUser?.getUserData();
      if ((userData as IUser).role === UserRoles.ADMIN) {
        appCookiesStorage.setItem(CURRENT_USER_EMAIL_STORAGE_KEY, values.email);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const jwtToken = result?.idToken?.jwtToken;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const refreshToken = result?.refreshToken?.token;

        if (jwtToken) {
          appCookiesStorage.setItem(AUTHORIZATION_TOKEN_STORAGE_KEY, jwtToken);
          appCookiesStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
          dispatch(setIsAuthorized(true));
        }
      } else {
        throw new Error('Not Admin user');
      }

      dispatch(setUserData(userData));
    } catch (e: unknown) {
      if (getErrorMessage(e) === RESPONSE_ERRORS.NOT_CONFITMED_USER) {
        showErrorToast({ message: t('auth_email_do_not_verified') });
      } else {
        showErrorToast({ message: t('auth_login_error_message') });
      }
    }
  };

  useEffect(() => {
    if (isLoading) return;
    if (isAuthorized || Cookies.get('auth_token')) {
      navigate(ROUTES_PATHS.KOL_LIST);
    }
  }, [isAuthorized, isLoading]);

  return (
    <StyledLayout>
      <StyledPageWrapper>
        <StyledFormWrapper>
          <LogInForm handleSubmit={login} isLoading={isLoading} />
        </StyledFormWrapper>
      </StyledPageWrapper>
    </StyledLayout>
  );
};

export default LogInContainer;
