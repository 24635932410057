import { IUser } from '@/models/admin.model';
import { IApiError } from '@/models/apiError.model';

export const AUTH_SLICE_NAME = 'auth';

export interface AuthState {
  isAuthorized: boolean;
  userEmail: string;
  authToken: string | null;
  isLoading: boolean;
  user: IUser | null;
  error: IApiError | null;
}

export const initialState: AuthState = {
  isAuthorized: false,
  userEmail: '',
  authToken: null,
  isLoading: true,
  user: null,
  error: null,
};

export interface ISignInDTO {
  email: string;
  password: string;
}

export interface ISignUpDTO {
  email: string;
  password: string;
  repeat_password: string;
}

export enum SignInFields {
  EMAIL = 'email',
  PASSWORD = 'password',
}
