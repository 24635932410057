import {
  AffiliateIcon,
  DashboardIcon,
  FileIcon,
  FilledFileIcon,
  MyAccountIcon,
  RoundArrowsIcon,
} from '@/icons';
import { DesktopOnly } from '@/modules/Adaptive';
import { IProjectTabsNames } from '../types';
import { AccountStatuses } from '@/constants';
import { SocialChannels } from '@/models/socialChannel.model';

export enum ProjectTabKeys {
  COMPANY_INFO = 'company-info',
  PROJECT_INFORMATION = 'project-information',
  SERVICE_AGREEMENT_DETAILS = 'service-agreement-details',
  AFFILIATE = 'affiliate',
  AFFILIATE_FUNDING = 'affiliate-funding',
  PROOF_OF_DELIVERY = 'proof-of-delivery',
  LEGAL_AGREEMENTS = 'legal-agreements',
}

export enum CompanyInfoTableTabKeys {
  COMPANY_INFO = 'company-info',
  CONTACT_PERSON = 'contact-person',
}

export const availableSocialChannelsList = [SocialChannels.YOUTUBE];

export const companyInfoTabsConfig = [
  {
    label: 'tabs_general_info_company_info_title',
    key: CompanyInfoTableTabKeys.COMPANY_INFO,
  },
  {
    label: 'tabs_contact_person_title',
    key: CompanyInfoTableTabKeys.CONTACT_PERSON,
  },
];

export const projectTabsConfig = [
  {
    label: 'company-info',
    key: ProjectTabKeys.COMPANY_INFO,
    name: IProjectTabsNames.COMPANY_INFO,
    icon: (
      <DesktopOnly>
        <DashboardIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'project-information',
    key: ProjectTabKeys.PROJECT_INFORMATION,
    name: IProjectTabsNames.PROJECT_INFORMATION,
    icon: (
      <DesktopOnly>
        <MyAccountIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'service-agreement-details',
    key: ProjectTabKeys.SERVICE_AGREEMENT_DETAILS,
    name: IProjectTabsNames.SERVICE_AGREEMENT_DETAILS,
    icon: (
      <DesktopOnly>
        <FilledFileIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'affiliate',
    key: ProjectTabKeys.AFFILIATE,
    name: IProjectTabsNames.AFFILIATE,
    icon: (
      <DesktopOnly>
        <AffiliateIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'affiliate-funding',
    key: ProjectTabKeys.AFFILIATE_FUNDING,
    name: IProjectTabsNames.AFFILIATE_FUNDING,
    icon: (
      <DesktopOnly>
        <AffiliateIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'proof-of-delivery',
    key: ProjectTabKeys.PROOF_OF_DELIVERY,
    name: IProjectTabsNames.PROOF_OF_DELIVERY,
    icon: (
      <DesktopOnly>
        <RoundArrowsIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'legal-agreements',
    key: ProjectTabKeys.LEGAL_AGREEMENTS,
    name: IProjectTabsNames.LEGAL_AGREEMENTS,
    icon: (
      <DesktopOnly>
        <FileIcon />
      </DesktopOnly>
    ),
  },
];

export const statusesConfigs = [
  {
    value: AccountStatuses.VERIFIED,
    label: 'Verified',
    statuses: [AccountStatuses.PAUSED, AccountStatuses.VERIFIED],
  },
  {
    value: AccountStatuses.PENDING,
    label: 'Pending',
    statuses: [AccountStatuses.PENDING],
  },
  {
    value: AccountStatuses.PAUSED,
    label: 'Paused',
    statuses: [AccountStatuses.PAUSED, AccountStatuses.VERIFIED],
  },
  {
    value: AccountStatuses.CANCELED,
    label: 'Canceled',
    statuses: [
      AccountStatuses.CANCELED,
      AccountStatuses.VERIFIED,
      AccountStatuses.PENDING,
      AccountStatuses.PAUSED,
    ],
  },
  {
    value: AccountStatuses.ARCHIVED,
    label: 'Archived',
    statuses: [AccountStatuses.CANCELED],
  },
];
