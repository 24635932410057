// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Panel } from '@/ui';
import {
  StyledWrapper,
  StyledCollapse,
  StyledTitle,
  StyledText,
} from './ProjectAnswersStyles';

// Models
import { IQuestionnaire } from '@/models/fundingRound.model';

interface IProjectAnswers {
  questionnaire?: IQuestionnaire;
}

const ProjectAnswers = ({ questionnaire }: IProjectAnswers) => {
  const { t } = useTranslation(['fundingRound']);

  const mappedQuestionnaire = useMemo(() => {
    return Object.entries(questionnaire ?? {}).map(([key, value]) => (
      <Panel
        header={<StyledText>{t(`funding_answers_${key}_title`)}</StyledText>}
        key={key}
      >
        <StyledText $isColored>{value}</StyledText>
      </Panel>
    ));
  }, [questionnaire]);

  return (
    <StyledWrapper>
      <StyledTitle>{t('funding_answers_for_project')}</StyledTitle>
      <StyledCollapse>{mappedQuestionnaire}</StyledCollapse>
    </StyledWrapper>
  );
};

export default ProjectAnswers;
