// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  TableContainer,
  TableHeader,
  TableHeaderCell,
} from './BalanceSummaryReportStyles';
import BalanceSummaryReportRow from './BalanceSummaryReportRow';

// Models
import { TFundingRoundPayoutBalanceAndTransfers } from '@/models/reports.model';
import { WalletNetworksEnum, CryptoCurrenciesEnum } from '@/models/web3.model';

// Modules
import { SEEDHUNTER_ETH_WALLET } from '@/modules/Web3';

interface IReportDetails {
  report: TFundingRoundPayoutBalanceAndTransfers;
  walletAddress?: string;
  targetNetwork?: WalletNetworksEnum;
  commissionTotal?: number;
  walletCurrency?: CryptoCurrenciesEnum;
}

const BalanceSummaryReport = ({
  report,
  walletAddress,
  walletCurrency,
  targetNetwork,
  commissionTotal,
}: IReportDetails) => {
  const { t } = useTranslation(['reports']);

  return (
    <TableContainer>
      <TableHeader>
        <TableHeaderCell>
          {t('reports_funding_balance_report_network')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_raised')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_commission', {
            commission: report?.seedHunterCommissionPercent,
          })}
        </TableHeaderCell>

        <TableHeaderCell>
          {t('reports_funding_balance_report_balance')}
        </TableHeaderCell>

        <TableHeaderCell>
          {t('reports_funding_balance_report_balance_transfer')}
        </TableHeaderCell>

        <TableHeaderCell>
          {t('reports_funding_balance_report_transfer')}
        </TableHeaderCell>
      </TableHeader>

      {report.POLYGON && (
        <BalanceSummaryReportRow
          data={report.POLYGON}
          network={WalletNetworksEnum.POLYGON}
          targetNetwork={targetNetwork}
          walletAddress={walletAddress}
          walletCurrency={walletCurrency}
          totalCommission={commissionTotal}
        />
      )}
      {report.SEPOLIA && (
        <BalanceSummaryReportRow
          data={report.SEPOLIA}
          network={WalletNetworksEnum.SEPOLIA}
          targetNetwork={targetNetwork}
          walletAddress={walletAddress}
          walletCurrency={walletCurrency}
          totalCommission={commissionTotal}
        />
      )}
      {report.ETHEREUM && (
        <BalanceSummaryReportRow
          data={report.ETHEREUM}
          network={WalletNetworksEnum.ETHEREUM}
          targetNetwork={targetNetwork}
          walletAddress={SEEDHUNTER_ETH_WALLET}
          walletCurrency={walletCurrency}
          totalCommission={commissionTotal}
        />
      )}
    </TableContainer>
  );
};

export default BalanceSummaryReport;
