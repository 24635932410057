import { TFunction } from 'react-i18next';
import { RequestsTypesKeys } from '@/models/requests.model';

export const getResponsibleLabel = (t: TFunction, type: string) => {
  switch (type) {
    case RequestsTypesKeys.PROFILE_VERIFICATION:
    case RequestsTypesKeys.PROFILE_PRE_VERIFICATION:
    case RequestsTypesKeys.SUBMIT_CONTENT_PLAN:
    case RequestsTypesKeys.POSTS_CONTEST:
    case RequestsTypesKeys.FUNDING_ROUND_APPLICATION:
      return t('requests_list_project_management');
    case RequestsTypesKeys.PROFILE_CHANGE:
      return t('requests_list_customer_support');
    case RequestsTypesKeys.TGE_DATE_CHANGE:
      return t('requests_list_kol_manager');
    case RequestsTypesKeys.CONTACT_SUPPORT_TGE:
      return t('requests_list_customer_support');
    case RequestsTypesKeys.CHECK_INVESTMENT:
    case RequestsTypesKeys.CAMPAIGN_VERIFICATION:
    case RequestsTypesKeys.CAMPAIGN_PARTICIPATION_REJECT:
    case RequestsTypesKeys.REFUND:
    case RequestsTypesKeys.PAYOUT:
    case RequestsTypesKeys.AFFILIATE_WITHDRAWAL:
    case RequestsTypesKeys.FUNDING_ROUND_PAYOUT:
    case RequestsTypesKeys.FUNDING_ROUND_CREDITS_AND_ETHEREUM:
    case RequestsTypesKeys.FUNDING_ROUND_REFUND:
      return t('requests_list_finance');
    default:
      return '';
  }
};
