import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {
  exportAllPayouts,
  exportFundingRoundsPayouts,
  exportFundingRoundsRefunds,
  exportFundingRefundById,
  exportKolsList,
  exportPayoutsByCampaignId,
  exportProjectsList,
  exportPostsByCampaignId,
  exportFundingReport,
  exportCampaignReport,
} from './actionCreators';
import { showApiErrors, fileDownloadByLink } from '@/utils';
import {
  DATA_EXPORTS_SLICE_NAME,
  DataExportsState,
  initialState,
} from './models';

export const slice = createSlice({
  name: DATA_EXPORTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isFulfilled(
          exportAllPayouts,
          exportFundingRoundsPayouts,
          exportFundingRoundsRefunds,
          exportFundingRefundById,
          exportPayoutsByCampaignId,
          exportKolsList,
          exportProjectsList,
          exportPostsByCampaignId,
          exportFundingReport,
          exportCampaignReport,
        ),
        (state: DataExportsState, action) => {
          fileDownloadByLink(action.payload.url);
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          exportAllPayouts,
          exportFundingRoundsPayouts,
          exportFundingRoundsRefunds,
          exportFundingRefundById,
          exportPayoutsByCampaignId,
          exportKolsList,
          exportProjectsList,
          exportPostsByCampaignId,
          exportFundingReport,
          exportCampaignReport,
        ),
        (state: DataExportsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          exportAllPayouts,
          exportFundingRoundsPayouts,
          exportFundingRoundsRefunds,
          exportFundingRefundById,
          exportPayoutsByCampaignId,
          exportKolsList,
          exportProjectsList,
          exportPostsByCampaignId,
          exportFundingReport,
          exportCampaignReport,
        ),
        (state: DataExportsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export default slice.reducer;
