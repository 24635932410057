// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfoWrapper,
  StyledInfoTitle,
  StyledText,
  CardWrapper,
} from './KOLsRankingStyles';
import { Link } from '@/ui';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { IKolsRankingItem } from '@/models/projects.model';

// Helpers
import { capitalizeString, getDateString, numberWithCommas } from '@/utils';

interface IKOLsRankingCard {
  data: IKolsRankingItem;
}

const KOLsRankingCard = ({ data }: IKOLsRankingCard) => {
  const { t } = useTranslation(['projectsList']);

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_influencer')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>{data?.kolAlias}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_twitter')}
          </StyledInfoTitle>
        </MobileOnly>
        <Link to={data?.socialChannelProfile} target={'_blank'}>
          {capitalizeString(data?.socialChannel)}
        </Link>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_followers')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.followers) ?? 0}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_post_price')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>${numberWithCommas(data?.pricePerPost) ?? 0}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_impact_score')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.impactScore) ?? 0}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_project_match')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.projectMatch) ?? 0}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_engagement_rate')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.engagementRate) ?? 0}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_reach_rate')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>${numberWithCommas(data?.reachRate) ?? 0}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_grade_score')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>{data?.gradeScore}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_coordinated_followers')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>
          {numberWithCommas(data?.coordinatedFollowers) ?? 0}
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('projects_kols_ranking_processed_at')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>
          {data?.processedAt
            ? getDateString(data?.processedAt)
            : t('projects_kols_ranking_processing')}
        </StyledText>
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default KOLsRankingCard;
