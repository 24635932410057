// Globals
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap, Link } from '@/ui';
import {
  StyledTitles,
  PayoutListWrapper,
  StyledButtonWrap,
  StyledLinkButton,
} from './FundingRoundPayoutDetailsStyles';
import { StyledInfoTitles } from '../Styled';
import FundingRoundDetailsCard from '../FundingRoundDetailsCard/FundingRoundDetailsCard';

// Modules
import { IFundingPayoutDetails } from '@/models/payout.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';
import { IReportsTabsNames } from '@/modules/Reports';

// Helpers
import { ROUTES_PATHS } from '@/router';

interface IFundingRoundPayoutDetails {
  fundingPayoutDetails?: IFundingPayoutDetails;
  isLoading: boolean;
}

const FundingRoundPayoutDetails = ({
  isLoading,
  fundingPayoutDetails,
}: IFundingRoundPayoutDetails) => {
  const { t } = useTranslation(['payout']);
  const loaderRef = useRef<HTMLDivElement>(null);

  return (
    <PayoutListWrapper>
      <StyledButtonWrap>
        <Link
          to={`${ROUTES_PATHS.REPORT_DETAILS}/${
            IReportsTabsNames.FUNDING_ROUND
          }/${encodeURIComponent(fundingPayoutDetails?.id ?? '')}`}
          target="_blank"
        >
          <StyledLinkButton type="primary">
            {t('payout_funding_go_to_report')}
          </StyledLinkButton>
        </Link>
      </StyledButtonWrap>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('payout_project_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_contact_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_wallet_title')}</StyledTitles>
          <StyledTitles>{t('payout_document_title')}</StyledTitles>
          <StyledTitles>
            {t('payout_funding_round_transfer_title')}
          </StyledTitles>
          <StyledTitles>{t('payout_funding_round_currency')}</StyledTitles>
          <StyledTitles>{t('payout_action_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      <FundingRoundDetailsCard
        data={fundingPayoutDetails as IFundingPayoutDetails}
      />
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </PayoutListWrapper>
  );
};

export default FundingRoundPayoutDetails;
