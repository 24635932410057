/* eslint @typescript-eslint/no-explicit-any: 0 */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { BASE_URL } from './constants';
import {
  AUTHORIZATION_TOKEN_STORAGE_KEY,
  CURRENT_USER_EMAIL_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from '@/constants';
import { appCookiesStorage, isSessionError, isUndefined } from '@/utils';
import { logOut } from '@/modules/auth';
import CognitoUserInstance from './cognito/CognitoUserInstance';
import { store } from '../store';

interface HttpClientError extends AxiosError {
  config: AxiosRequestConfig;
}

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.defaults.baseURL = BASE_URL;

let cookiesAuthToken: null | string = null;

const requestInterceptor = async (config: AxiosRequestConfig) => {
  const isUrlSearchParams = config.params instanceof URLSearchParams;
  const authToken =
    cookiesAuthToken ??
    appCookiesStorage.getItem(AUTHORIZATION_TOKEN_STORAGE_KEY) ??
    (isUrlSearchParams
      ? config.params.get(AUTHORIZATION_TOKEN_STORAGE_KEY)
      : (config?.params?.[AUTHORIZATION_TOKEN_STORAGE_KEY] as string)) ??
    '';

  const updated = {
    ...config,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  return updated;
};

const errorResponseInterceptor = async (
  error: HttpClientError & { config: { isRetry: boolean } },
) => {
  if (isSessionError(error) && !error?.config.isRetry) {
    const userEmail =
      appCookiesStorage.getItem(CURRENT_USER_EMAIL_STORAGE_KEY) ?? '';
    const refreshToken =
      appCookiesStorage.getItem(REFRESH_TOKEN_STORAGE_KEY) ??
      error.config.params[REFRESH_TOKEN_STORAGE_KEY] ??
      '';

    if (isUndefined(refreshToken)) {
      store.dispatch(logOut());
      throw error;
    }

    const cognitoUser = new CognitoUserInstance(userEmail);
    const session = await cognitoUser.refreshSession(refreshToken);

    const updatedJwtToken = session?.getIdToken()?.getJwtToken();
    const updatedRefreshToken = session?.getRefreshToken()?.getToken();

    if (!updatedJwtToken || !updatedRefreshToken) {
      throw error;
    }
    appCookiesStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, updatedRefreshToken);

    appCookiesStorage.setItem(AUTHORIZATION_TOKEN_STORAGE_KEY, updatedJwtToken);

    cookiesAuthToken = updatedJwtToken;

    const newRequest = {
      ...error.config,
      isRetry: true,
    };

    return instance(newRequest);
  } else {
    throw error;
  }
};

/** Adding the request interceptors */
instance.interceptors.request.use(requestInterceptor);

/** Adding the response interceptors */
instance.interceptors.response.use(
  (r: AxiosResponse) => r,
  errorResponseInterceptor,
);

export type ApiResponse<Response> = Promise<AxiosResponse<Response>>;

export type TGenerateOptions = {
  method: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
  url: string;
  data?: any;
  params?: any;
  config?: AxiosRequestConfig;
  instance?: AxiosInstance;
};

export type TFormatResponse = {
  data: any;
  status: number;
  statusText: string;
  headers?: any;
};

export function httpPost<TData, TResponse>(
  url: string,
  data?: TData | string,
  params?: any,
): ApiResponse<TResponse> {
  return sendRequest({
    method: 'POST',
    url,
    data,
    params,
  });
}

export function httpGet<TResponse>(
  url: string,
  params?: any,
): ApiResponse<TResponse> {
  return sendRequest({ method: 'GET', url, params });
}
export const httpPatch = (url: string, data: any): Promise<TFormatResponse> =>
  sendRequest({ method: 'PATCH', url, data });
export const httpDelete = (url: string, data?: any): Promise<TFormatResponse> =>
  sendRequest({ method: 'DELETE', url, data });
export const httpPut = (url: string, data: any): Promise<TFormatResponse> =>
  sendRequest({ method: 'PUT', url, data });

export const formatResponse = (response: any = {}): TFormatResponse => ({
  data: response.data ?? {},
  status: response.status || 418,
  statusText: response.statusText ?? '',
  headers: response.headers ?? {},
});

async function sendRequest<TResponse>({
  method,
  url,
  data = undefined,
  params = undefined,
}: TGenerateOptions): Promise<AxiosResponse<TResponse>> {
  const OPTIONS = generateOptions({
    method,
    url,
    data,
    params,
  });

  return instance(url, OPTIONS);
}

const generateOptions = ({ method, url, data, params }: TGenerateOptions) => {
  const defaultHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
  };

  const token = appCookiesStorage.getItem(AUTHORIZATION_TOKEN_STORAGE_KEY);

  const authHeaders = {
    Authorization: `Bearer ${token}`,
  };

  return {
    method,
    url,
    data,
    params,
    headers: {
      ...defaultHeaders,
      ...(token ? authHeaders : {}),
    },
  };
};

export const isHttpClientError = axios.isAxiosError;
