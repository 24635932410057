// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  TableContainer,
  TableHeader,
  TableHeaderCell,
} from './BalanceReportStyles';
import BalanceReportRow from '../BalanceReport/BalanceReportRow';

// Models
import { TFundingRoundPayoutBalanceAndTransfers } from '@/models/reports.model';
import { WalletNetworksEnum, CryptoCurrenciesEnum } from '@/models/web3.model';

// Modules
import { SEEDHUNTER_ETH_WALLET } from '@/modules/Web3';

interface IReportDetails {
  report: TFundingRoundPayoutBalanceAndTransfers;
  nonTargetNetworksCommission?: number;
  totalCredits?: number;
  walletAddress?: string;
  targetNetwork?: WalletNetworksEnum;
  targetCurrency?: CryptoCurrenciesEnum;
}

const BalanceReport = ({
  report,
  walletAddress,
  targetCurrency,
  targetNetwork,
  nonTargetNetworksCommission,
  totalCredits,
}: IReportDetails) => {
  const { t } = useTranslation(['reports']);

  return (
    <TableContainer>
      <TableHeader>
        <TableHeaderCell>
          {t('reports_funding_balance_report_network')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_raised')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_credit_used')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_initial_balance')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_swap')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_balance_transfer')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_balance')}
        </TableHeaderCell>
      </TableHeader>

      {report.POLYGON && (
        <BalanceReportRow
          data={report.POLYGON}
          network={WalletNetworksEnum.POLYGON}
          targetCurrency={targetCurrency}
          targetNetwork={targetNetwork}
          walletAddress={walletAddress}
          totalCredits={totalCredits}
          nonTargetNetworksCommission={nonTargetNetworksCommission}
        />
      )}
      {report.SEPOLIA && (
        <BalanceReportRow
          data={report.SEPOLIA}
          targetCurrency={targetCurrency}
          targetNetwork={targetNetwork}
          nonTargetNetworksCommission={nonTargetNetworksCommission}
          network={WalletNetworksEnum.SEPOLIA}
          walletAddress={walletAddress}
          totalCredits={totalCredits}
        />
      )}
      {report.ETHEREUM && (
        <BalanceReportRow
          data={report.ETHEREUM}
          network={WalletNetworksEnum.ETHEREUM}
          targetCurrency={targetCurrency}
          targetNetwork={targetNetwork}
          totalCredits={totalCredits}
          nonTargetNetworksCommission={nonTargetNetworksCommission}
          walletAddress={SEEDHUNTER_ETH_WALLET}
        />
      )}
    </TableContainer>
  );
};

export default BalanceReport;
