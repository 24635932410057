// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  FormFields,
  ImageUploadsWrap,
  StyledUploadFilePlaceholder,
} from './ProjectUploadsStyles';
import { FormItem, IFormInstance } from '@/components';
import { UploadIcon } from '@/icons';

// Modules
import {
  DOCS_MIME_TYPES,
  FileUpload,
  ImageUpload,
  MAX_IMAGE_SIZE,
  uploadFile,
} from '@/modules/Uploaders';
import { isProjectDisabled } from '../../helpers';
import { ProjectInformationFieldsNames, ProjectFieldsNames } from '../../types';

// Helpers | Hooks
import { useAppDispatch, useBoolean } from '@/hooks';
import { useGetFormValidationRules } from '../../hooks';

interface IProjectUploads {
  formInstance: IFormInstance;
  status?: string;
}

const ProjectUploadsEdit = ({ formInstance, status }: IProjectUploads) => {
  const { t } = useTranslation(['projectsList']);
  const {
    value: isLoading,
    setTrue: setLoading,
    setFalse: unsetLoading,
  } = useBoolean();
  const validationRules = useGetFormValidationRules();
  const dispatch = useAppDispatch();
  const isUploadsDisabled = isProjectDisabled(status);
  const handleChange = async (key: string, value: File | Blob | null) => {
    if (!value) {
      return formInstance.setFieldValue(key, value);
    }
    setLoading();
    const cloudfrontUrl = await dispatch(
      uploadFile({ key, value: value as File }),
    ).unwrap();

    formInstance.setFieldValue(key, cloudfrontUrl);
    unsetLoading();
  };

  return (
    <FormFields>
      <ImageUploadsWrap>
        <FormItem
          name={ProjectFieldsNames.IMAGE}
          label={t('projects_information_project_image')}
          rules={validationRules[ProjectInformationFieldsNames.IMAGE]}
        >
          <ImageUpload
            disabled={isUploadsDisabled}
            isLoading={isLoading}
            onChange={(v) =>
              handleChange(ProjectInformationFieldsNames.IMAGE, v)
            }
          />
        </FormItem>
        <FormItem
          name={ProjectInformationFieldsNames.WHITEPAPER}
          label={t('projects_information_whitepaper_title')}
          rules={validationRules[ProjectInformationFieldsNames.WHITEPAPER]}
        >
          <FileUpload
            disabled={isUploadsDisabled}
            fileMaxSize={MAX_IMAGE_SIZE}
            acceptance={DOCS_MIME_TYPES}
            isLoading={isLoading}
            onChange={(v) =>
              handleChange(ProjectInformationFieldsNames.WHITEPAPER, v)
            }
          >
            <StyledUploadFilePlaceholder>
              <UploadIcon />
              {t('file_upload_placeholder', { ns: 'common' })}
            </StyledUploadFilePlaceholder>
          </FileUpload>
        </FormItem>
      </ImageUploadsWrap>
    </FormFields>
  );
};

export default ProjectUploadsEdit;
