import dayjs from 'dayjs';
import { IFundingRound } from '@/models/fundingRound.model';
import { defaultAdditionalPercentage } from '../constants';
import { WalletNetworksEnum } from '@/models/web3.model';

export const normalizeFundingRoundData = (data: IFundingRound | null) => {
  return {
    ...data,
    plannedTGEDate: data?.plannedTGEDate && dayjs(data?.plannedTGEDate),
    startDate: data?.startDate && dayjs(data?.startDate),
    endDate: data?.endDate && dayjs(data?.endDate),
    progressBarAdditionalPercentage:
      data?.progressBarAdditionalPercentage ?? defaultAdditionalPercentage,
    walletNetwork: WalletNetworksEnum.POLYGON,
  } as IFundingRound;
};
