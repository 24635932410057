// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Models
import { IProject } from '@/models/projects.model';

// Components
import {
  StyledA,
  StyledDetails,
  StyledText,
} from '../ProjectMainInformation/ProjectMainInformationStyles';
import { StyledBadges, StyledBadgesWrapper } from './ProjectUploadsStyles';
import { IFormInstance } from '@/components';
import ProjectUploadsEdit from './ProjectUploadsEdit';

interface IProjectUploads {
  projectDetails: IProject | null;
  isEditActive: boolean;
  isLoading: boolean;
  formInstance: IFormInstance;
  initialValues: IProject;
}

const ProjectUploads = ({
  isEditActive,
  formInstance,
  projectDetails,
}: IProjectUploads) => {
  const { t } = useTranslation(['projectsList']);

  return isEditActive ? (
    <ProjectUploadsEdit
      formInstance={formInstance}
      status={projectDetails?.status}
    />
  ) : (
    <StyledDetails>
      <StyledText>{t('projects_information_project_uploads_title')}</StyledText>
      <StyledBadgesWrapper>
        {projectDetails?.projectImage && (
          <StyledA href={projectDetails?.projectImage} target="_blank">
            <StyledBadges>
              {t('projects_information_project_image')}
            </StyledBadges>
          </StyledA>
        )}
        {projectDetails?.whitepaper && (
          <StyledA href={projectDetails?.whitepaper} target="_blank">
            <StyledBadges>
              {t('projects_information_whitepaper_title')}
            </StyledBadges>
          </StyledA>
        )}
      </StyledBadgesWrapper>
    </StyledDetails>
  );
};

export default ProjectUploads;
