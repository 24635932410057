// Globals
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Modules
import { logOut } from '@/modules/auth';
import { useIsMobile } from '@/modules/Adaptive';
import { selectAdminPermissions } from '@/modules/Permissions';
import { checkRoutePermission } from '@/modules/Permissions/helpers';

// Helpers
import { ROUTES_PATHS } from '@/router';
import { useCognito, useAppDispatch, useAppState } from '@/hooks';
import {
  MenuItemsKeys,
  menuItems,
  dividerItem,
} from '../constants/sidebarConfigs';
import { sideBarMenuBuilder } from '../helpers/sideBar';

const useSidebarMenu = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cognitoUser } = useCognito();
  const { isMobile } = useIsMobile();
  const adminPermissions = useAppState(selectAdminPermissions);

  const getCurrentSideBarConfig = () => {
    return (
      menuItems.filter((value) => {
        const notAllowedByPermissions =
          value.route &&
          !checkRoutePermission(
            value.route as ROUTES_PATHS,
            adminPermissions ?? [],
          );

        const skipLogout = isMobile && value.key === MenuItemsKeys.LOG_OUT;
        const skipOptions = !isMobile && value.key === MenuItemsKeys.OPTIONS;

        const notSatisfied =
          skipOptions ||
          skipLogout ||
          (isMobile && !value.isMobileShown) ||
          notAllowedByPermissions;
        return !notSatisfied;
      }) ?? []
    );
  };

  const sideBarConfig = sideBarMenuBuilder({
    t,
    sideBarConfig: getCurrentSideBarConfig(),
    isMobile,
  });

  if (!isMobile) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sideBarConfig?.splice(sideBarConfig.length - 1, 0, dividerItem);
  }

  const handleLogOut = useCallback(() => {
    dispatch(logOut());
    cognitoUser?.logOut();
    navigate(ROUTES_PATHS.SIGN_IN);
  }, [cognitoUser, dispatch, navigate]);

  const handleMenuClick = useCallback(
    ({ key }: { key: string }) => {
      if (key === MenuItemsKeys.LOG_OUT) {
        handleLogOut();
      }
    },
    [handleLogOut],
  );

  return { items: sideBarConfig, handleMenuClick };
};

export default useSidebarMenu;
