// Globals
import React from 'react';

// Components
import {
  TableRow,
  TableCell,
  NetworkTitle,
} from './BalanceSummaryReportStyles';
import { Tooltip } from '@/ui';

// Modules
import { networksConfig } from '@/modules/Web3';
import { renderCurrenciesValues } from '@/modules/Reports/helpers/dataRender';

// Models
import { TNetworkTransfersData } from '@/models/reports.model';
import { WalletNetworksEnum, CryptoCurrenciesEnum } from '@/models/web3.model';
import { capitalizeString, numberWithCommas } from '@/utils';

interface IBalanceReportRow {
  data: TNetworkTransfersData;
  network: WalletNetworksEnum;
  targetNetwork?: WalletNetworksEnum;
  walletCurrency?: CryptoCurrenciesEnum;
  walletAddress?: string;
  totalCommission?: number;
}
const BalanceSummaryReportRow = ({
  data,
  network,
  totalCommission,
  walletCurrency,
  targetNetwork,
  walletAddress,
}: IBalanceReportRow) => {
  const isTargetNetwork = targetNetwork === network;
  const targetBalance =
    (data.raisedBudget?.totalByNetwork ?? 0) +
    (totalCommission ?? 0) -
    (Number(data?.seedHunterCommissionAmount?.totalByNetwork) ?? 0);

  const nonTargetBalance =
    (data.raisedBudget?.totalByNetwork ?? 0) -
    (data?.seedHunterCommissionAmount?.totalByNetwork ?? 0);

  const balance = isTargetNetwork ? targetBalance : nonTargetBalance;

  return (
    <TableRow>
      <TableCell>
        <img src={networksConfig[network].image} alt="" />
        <NetworkTitle>{capitalizeString(network)}</NetworkTitle>
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title={walletAddress}
          placement={'top'}
        >
          <NetworkTitle>{walletAddress}</NetworkTitle>
        </Tooltip>
      </TableCell>

      <TableCell>{data.raisedBudget?.totalByNetwork}</TableCell>

      <TableCell>
        {data.seedHunterCommissionAmount &&
          renderCurrenciesValues(data.seedHunterCommissionAmount)}
      </TableCell>

      <TableCell>
        {balance ? `${walletCurrency} ${numberWithCommas(balance)}` : 0}
      </TableCell>

      <TableCell>
        {totalCommission ? `- ${walletCurrency} ${totalCommission}` : 0}
      </TableCell>

      <TableCell>
        {data.projectTransfer && renderCurrenciesValues(data.projectTransfer)}
      </TableCell>
    </TableRow>
  );
};

export default BalanceSummaryReportRow;
