import { useTranslation } from 'react-i18next';
import {
  useGetEmailRule,
  useLengthValidationRule,
  useGetPhoneRule,
  useGetRequiredRule,
} from '@/hooks';
import { GeneralInfoFieldsNames } from '@/modules/PartnersList/helpers';
import { ProjectFieldsNames, ContactPersonFieldsNames } from '../types';
import { TextLength } from '@/constants';

const useGetFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });
  const textFieldLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.short,
  });
  const textSloganLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.titleMedium,
  });
  const textTitleLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.titleShort,
  });
  const descriptionFieldLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.large,
  });
  const phone = useGetPhoneRule({ t });
  const email = useGetEmailRule({ t });

  return {
    [ContactPersonFieldsNames.FIRST_NAME]: [required, textFieldLength],
    [ContactPersonFieldsNames.LAST_NAME]: [required, textFieldLength],
    [ContactPersonFieldsNames.PHONE]: [required, phone],
    [ContactPersonFieldsNames.EMAIL]: [required, email],
    [ContactPersonFieldsNames.POSITION]: [required, textFieldLength],
    [ProjectFieldsNames.TITLE]: [required, textTitleLength],
    [ProjectFieldsNames.SUB_TITLE]: [required, textSloganLength],
    [ProjectFieldsNames.WEBSITE]: [required, textFieldLength],
    [ProjectFieldsNames.WHITEPAPER]: [],
    [ProjectFieldsNames.IMAGE]: [required],
    [ProjectFieldsNames.PITCH_DECK]: [required],
    [ProjectFieldsNames.MAIN_COMMUNITY]: [required],
    [ProjectFieldsNames.SECONDARY_COMMUNITY]: [required],
    [ProjectFieldsNames.PRIMARY_GEOGRAPHY]: [required],
    [ProjectFieldsNames.SECONDARY_GEOGRAPHY]: [required],
    [ProjectFieldsNames.DESCRIPTION]: [required, descriptionFieldLength],
    [ContactPersonFieldsNames.FORM_OF_ADDRESS]: [required],
    [GeneralInfoFieldsNames.WALLET_ADDRESS]: [required],
    [GeneralInfoFieldsNames.WALLET_CURRENCY]: [required],
  };
};

export default useGetFormValidationRules;
