import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  FormFieldsWrapper,
  FormTextWrapper,
  StyledWalletWrapper,
  StyledDetails,
  StyledSubTitle,
  StyledText,
  StyledTitle,
  StyledInfo,
  StyledButtonWrapper,
  StyledButton,
  StyledStatusWrapper,
} from './WalletInfoStyles';
import { Spinner, Tooltip } from '@/ui';

// Models
import {
  SeedHunterWalletCreationStatus,
  IProject,
} from '@/models/projects.model';

// Hooks | Utils
import { AccountStatuses } from '@/constants';
import { useAppDispatch } from '@/hooks';
import { getUserId } from '@/utils';
import { showSuccessToast } from '@/components';

interface IWalletInfo {
  isLoading?: boolean;
  projectData?: IProject | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  walletCreation?: any;
}

const WalletInfo = ({
  projectData,
  isLoading,
  walletCreation,
}: IWalletInfo) => {
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const disabledSeedHunterWallet =
    projectData?.status !== AccountStatuses.VERIFIED &&
    projectData?.seedHunterWalletCreationStatus !==
      SeedHunterWalletCreationStatus.IN_PROGRESS;

  const createWallet = () => {
    dispatch(walletCreation({ id: getUserId(projectData?.id ?? '') }));
    showSuccessToast({
      message: t('projects_wallets_create_wallet_massage'),
    });
  };

  return (
    <StyledWalletWrapper>
      <FormTextWrapper>
        <StyledTitle>{t('projects_wallets_title')}</StyledTitle>
      </FormTextWrapper>
      <FormFieldsWrapper>
        <StyledInfo>
          <StyledDetails>
            <StyledSubTitle>
              {t('projects_general_info_seed_wallet_balance_title')}
            </StyledSubTitle>
            <StyledText>{projectData?.seedHunterWalletBalance}</StyledText>
          </StyledDetails>

          <StyledDetails>
            <StyledSubTitle>
              {t('projects_general_info_seed_wallet_network_title')}
            </StyledSubTitle>
            <StyledText>{projectData?.seedHunterWalletNetwork}</StyledText>
          </StyledDetails>

          <StyledDetails>
            <StyledSubTitle>
              {t('projects_general_info_seed_wallet_address_title')}
            </StyledSubTitle>
            <Tooltip
              getPopupContainer={(triggerNode) => triggerNode}
              title={projectData?.seedHunterWalletAddress}
              placement={'top'}
            >
              <StyledText>{projectData?.seedHunterWalletAddress}</StyledText>
            </Tooltip>
          </StyledDetails>
          {projectData?.seedHunterWalletCreationStatus !==
            SeedHunterWalletCreationStatus.SUCCESS && (
            <StyledButtonWrapper>
              {isLoading ? (
                <Spinner />
              ) : (
                <StyledStatusWrapper
                  $status={projectData?.seedHunterWalletCreationStatus}
                >
                  {t(
                    `projects_general_seedHunter_status_${projectData?.seedHunterWalletCreationStatus}`,
                  )}
                </StyledStatusWrapper>
              )}
              <StyledButton
                type="primary"
                htmlType="submit"
                onClick={createWallet}
                disabled={disabledSeedHunterWallet}
              >
                {t('projects_wallets_create_button')}
              </StyledButton>
            </StyledButtonWrapper>
          )}
        </StyledInfo>
        <StyledInfo>
          {projectData?.savedTransactionCurrency && (
            <StyledDetails>
              <StyledSubTitle>
                {t('projects_general_info_saved_currency')}
              </StyledSubTitle>
              <StyledText>{projectData?.savedTransactionCurrency}</StyledText>
            </StyledDetails>
          )}

          {projectData?.savedTransactionNetwork && (
            <StyledDetails>
              <StyledSubTitle>
                {t('projects_general_info_saved_network')}
              </StyledSubTitle>
              <StyledText>{projectData?.savedTransactionNetwork}</StyledText>
            </StyledDetails>
          )}
        </StyledInfo>
      </FormFieldsWrapper>
    </StyledWalletWrapper>
  );
};

export default WalletInfo;
