import { IApiError } from '@/models/apiError.model';
import {
  IGetKOLsListDTO,
  IKOL,
  MarketingAffiliateCommission,
} from '@/models/kolsList.model';
import { IUser } from '@/models/admin.model';

export const KOLS_LIST_SLICE_NAME = 'kols-list';

export interface KOLsListState {
  kolDetails: IKOL | null;
  kolsListData: {
    items: IKOL[] | null;
    hasMore: boolean;
  };
  affiliateData: {
    items: MarketingAffiliateCommission[] | null;
    hasMore: boolean;
  };
  isLoading: boolean;
  invitedUsers: {
    items: IUser[] | null;
    hasMore: boolean;
  };
  influencerDataError: { [key: string]: string } | null;
  influencerDataIsLoading: boolean;
  kolFilters: IGetKOLsListDTO;
  error: IApiError | null;
}

export const initialState: KOLsListState = {
  kolsListData: {
    items: [],
    hasMore: true,
  },
  affiliateData: {
    items: [],
    hasMore: true,
  },
  invitedUsers: {
    items: [],
    hasMore: true,
  },
  kolFilters: {
    search: undefined,
    status: undefined,
  },
  kolDetails: null,
  isLoading: false,
  influencerDataError: null,
  influencerDataIsLoading: false,
  error: null,
};
