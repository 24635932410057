// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

// Components
import { showErrorToast } from '@/components';
import { Title, StyledPageWrapper } from '@/ui';
import { PartnersList } from '../../components';

// Modules
import { SearchInfoInput } from '@/modules/SharedProfile';

// Helpers | Hooks
import { useAppDispatch, useAppState } from '@/hooks';
import { partnersListSliceSelector } from '../../feature/selectors';
import { getPartnersList } from '../../feature/actionCreators';
import { resetPartnersList } from '../../feature/slice';

const PartnersListContainer = () => {
  const { t } = useTranslation(['partnersList']);
  const dispatch = useAppDispatch();

  const { partnersListData, isLoading } = useAppState(
    partnersListSliceSelector,
  );

  const getNextPartnersList = useCallback(
    debounce(() => {
      if (isLoading || !partnersListData?.hasMore) return;
      const lastItem = partnersListData?.items?.at(-1);
      dispatch(
        getPartnersList({
          startId: lastItem?.id,
        }),
      );
    }, 1000),
    [isLoading, dispatch, partnersListData?.hasMore],
  );

  const handlePartnersSearch = async (value: string) => {
    const partnersSearchedList = await dispatch(
      getPartnersList({
        search: value,
      }),
    ).unwrap();

    partnersSearchedList?.items?.length === 0 &&
      showErrorToast({
        message: t('empty_data_massage_partner', { ns: 'profile' }),
      });
  };

  useEffect(
    () => (): void => {
      dispatch(resetPartnersList());
    },
    [dispatch],
  );

  return (
    <StyledPageWrapper>
      <Title $type="h3">{t('hunters_list_title')}</Title>
      <SearchInfoInput handleSearch={handlePartnersSearch} />
      <PartnersList
        partners={partnersListData}
        isLoading={isLoading}
        getNextProjects={getNextPartnersList}
      />
    </StyledPageWrapper>
  );
};

export default PartnersListContainer;
