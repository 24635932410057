import { IGetListParamsBase } from './api.model';
import { CampaignStatuses } from './campaigns.model';
import { SocialChannels } from './socialChannel.model';

export enum MarketingDeliveryEndpoints {
  GET_PROJECTS = '/admin/projects',
  ROOT_CAMPAIGNS = '/admin/campaigns',
}

export interface IDeliveryStatistics {
  campaignId: string;
  campaignTitle: string;
  campaignStatus: CampaignStatuses;
  marketingStartDate: string;
  marketingEndDate: string;
  campaignBudget: number;
  plannedPosts: number;
  deliveredPosts: number;
}

export interface IMarketingDelivery {
  campaignId: string;
  campaignTitle: string;
  campaignBudget: number;
  plannedPosts: number;
  deliveredPosts: number;
}

export interface IMarketingDeliveryInfo {
  id?: string;
  sk?: string;
  projectId?: string;
  kolId?: string;
  campaignId?: string;
  type?: string;
  weekNumber?: string;
  socialChannel?: SocialChannels;
  pricePerPost?: number;
  link?: string;
  dateOfPublish?: string;
  alias?: string;
  adminEmail?: string;
}

export const initialPost = {
  link: undefined,
  kolId: undefined,
  date: undefined,
  calendarWeek: undefined,
  sw: undefined,
  socialChannel: undefined,
};

// ============= DTO =============

export interface IUpdateCampaignPostDTO {
  campaignId?: string;
  id?: string;
  sk?: string;
  kolId?: string;
  date?: string;
  link?: string;
  calendarWeek?: number;
  socialChannel?: SocialChannels;
}

export interface IDeliveryStatisticsDTO {
  campaignId?: string;
  id?: string;
}

export interface IMarketingDeliveryDetailsDTO extends IGetListParamsBase {
  id?: string;
  startSk?: string;
  kolId?: string;
  campaignId?: string;
}

export interface IRemoveCampaignPostDTO {
  campaignId?: string;
  id?: string;
  sk?: string;
}

// ============== Response ==============

export interface IGetMarketingDeliveryResponse {
  hasMore: boolean;
  items: IMarketingDelivery[];
}

export interface IGetMarketingDeliveryDetailsResponse {
  hasMore: boolean;
  items: IMarketingDeliveryInfo[];
}
