import { useEffect, useState } from 'react';
import { useAppState } from '@/hooks';
import { selectUserEmail } from '@/modules/auth';
import { CURRENT_USER_EMAIL_STORAGE_KEY } from '@/constants';
import { appCookiesStorage } from '@/utils';
import { CognitoUserInstance } from '@/api';

interface IUseCognitoReturn {
  cognitoUser: null | CognitoUserInstance;
}

const useCognito = (): IUseCognitoReturn => {
  const stateEmail = useAppState(selectUserEmail);
  const cookiesEmail = appCookiesStorage.getItem(
    CURRENT_USER_EMAIL_STORAGE_KEY,
  );
  const email = stateEmail || cookiesEmail;
  const [cognitoUser, setCognitoUser] = useState<null | CognitoUserInstance>(
    null,
  );

  useEffect(() => {
    if (email) {
      setCognitoUser(new CognitoUserInstance(email));
    }
  }, [email]);

  return {
    cognitoUser,
  };
};

export default useCognito;
