import {
  CognitoUserAttribute,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';
import { TSignUpFormValues } from '@/modules/auth/helpers/types';
import { AuthFieldsNames } from '@/types';
import { Statuses } from '@/constants';

const extraFields = [
  AuthFieldsNames.CREATE_PASSWORD,
  AuthFieldsNames.CONFIRM_PASSWORD,
];

// ============== Data normalize helpers ==============

export const normalizeCognitoAttributes = (
  attributes: ICognitoUserAttributeData[] | undefined,
) => {
  if (!attributes?.length) return;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any = {};
  if (attributes) {
    for (const attribute of attributes) {
      const { Name, Value } = attribute;
      const formattedName = Name.startsWith('custom')
        ? Name.slice(7).toLowerCase()
        : Name;
      res[formattedName] = Value;
    }
    return res;
  }
};

export const getCognitoAttributes = (
  values: TSignUpFormValues,
  invitedBy: string,
  role: string,
): CognitoUserAttribute[] => {
  const basicAttributes = Object.entries({ ...values })
    .filter((tuple) => !extraFields.includes(tuple[0] as AuthFieldsNames))
    .map(
      (tuple) =>
        new CognitoUserAttribute({
          Name: tuple[0],
          Value: tuple[1],
        }),
    );

  // return attributes with added role field :
  return [
    ...basicAttributes,
    new CognitoUserAttribute({
      Name: 'custom:Role',
      Value: role,
    }),
    new CognitoUserAttribute({
      Name: 'custom:invitedBy',
      Value: invitedBy,
    }),
    new CognitoUserAttribute({
      Name: 'custom:status',
      Value: Statuses.PENDING,
    }),
  ];
};
