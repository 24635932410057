// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';

// Components
import { Title } from '@/ui';
import {
  AffiliateList,
  CommissionReport,
  FundingRoundOverview,
  FundingParticipationsList,
} from '../../components';
import BalanceReport from '../../components/BalanceReport/BalanceReport';
import {
  BlockTitle,
  BlockWrapper,
  StyledButton,
} from './FundingRoundReportDetailsContainerStyles';
import { ExportIcon } from '@/icons';
import BalanceSummaryReport from '../../components/BalanceSummaryReport/BalanceSummaryReport';

// Models
import { TFundingRoundPayoutBalanceAndTransfers } from '@/models/reports.model';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';

// Modules
import {
  exportFundingReport,
  selectDataExportsIsLoading,
} from '@/modules/DataExports';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { getSumBy, getUserId } from '@/utils';
import { reportsSliceSelector } from '../../feature/selectors';
import {
  getFundingRoundReportAffiliate,
  getFundingRoundReportDetails,
  getFundingRoundReportParticipants,
} from '../../feature/actionCreators';

const FundingRoundReportDetailsContainer = () => {
  const { t } = useTranslation(['reports']);
  const dispatch = useAppDispatch();
  const exportLoading = useAppState(selectDataExportsIsLoading);
  const {
    reportAffiliateList,
    fundingReportDetails,
    fundingReportParticipants,
    isLoading,
  } = useAppState(reportsSliceSelector);
  const { id } = useParams();

  const exportReport = async () => {
    if (id) {
      await dispatch(exportFundingReport(getUserId(id))).unwrap();
    }
  };

  const affiliateReward = getSumBy(
    reportAffiliateList ?? [],
    'affiliateCommission',
  );

  const commissionTotal =
    fundingReportDetails?.balanceReport?.seedHunterCommissionAmountTotal ?? 0;
  const commissionBalance = commissionTotal - affiliateReward;
  const balanceReport =
    fundingReportDetails?.balanceReport as TFundingRoundPayoutBalanceAndTransfers;
  const nonTargetNetworkCommissions = Object.entries({
    [WalletNetworksEnum.SEPOLIA]: balanceReport?.SEPOLIA,
    [WalletNetworksEnum.ETHEREUM]: balanceReport?.ETHEREUM,
    [WalletNetworksEnum.POLYGON]: balanceReport?.POLYGON,
  }).reduce((acc, [key, curr]) => {
    const currentCommission =
      key === fundingReportDetails?.walletNetwork
        ? 0
        : curr?.seedHunterCommissionAmount?.totalByNetwork ?? 0;

    return acc + currentCommission;
  }, 0);

  useEffect(() => {
    if (id) {
      dispatch(getFundingRoundReportDetails(getUserId(id))).unwrap();
      dispatch(getFundingRoundReportParticipants(id));
      dispatch(getFundingRoundReportAffiliate(id));
    }
  }, [id]);

  if (isLoading) return <Skeleton />;

  if (!fundingReportDetails) return null;

  return (
    <>
      <Title $type="h3">{t('reports_title')}</Title>
      <StyledButton
        type="primary"
        onClick={exportReport}
        loading={exportLoading}
      >
        {t('file_export_pdf', { ns: 'common' })}
        <ExportIcon />
      </StyledButton>
      {fundingReportDetails && (
        <FundingRoundOverview report={fundingReportDetails} />
      )}

      <BlockWrapper>
        <BlockTitle>{t('reports_filters_participants')}</BlockTitle>
        <FundingParticipationsList
          participations={fundingReportParticipants}
          isLoading={isLoading}
        />
      </BlockWrapper>

      <BlockWrapper>
        <BlockTitle>{t('reports_funding_balance_check_report')}</BlockTitle>
        <BalanceReport
          report={balanceReport}
          nonTargetNetworksCommission={nonTargetNetworkCommissions}
          targetNetwork={
            fundingReportDetails?.walletNetwork as WalletNetworksEnum
          }
          targetCurrency={
            fundingReportDetails?.walletCurrency as CryptoCurrenciesEnum
          }
          walletAddress={fundingReportDetails?.walletAddress}
        />
        <BlockTitle>{t('reports_funding_project_transfer')}</BlockTitle>
        <BalanceSummaryReport
          report={balanceReport}
          commissionTotal={commissionTotal}
          targetNetwork={
            fundingReportDetails?.walletNetwork as WalletNetworksEnum
          }
          walletCurrency={
            fundingReportDetails?.walletCurrency as CryptoCurrenciesEnum
          }
          walletAddress={fundingReportDetails?.walletAddress}
        />
      </BlockWrapper>

      <BlockWrapper>
        <BlockTitle>{t('reports_funding_commission')}</BlockTitle>
        <CommissionReport
          commissionTotal={commissionTotal}
          affiliateReward={affiliateReward}
          commissionBalance={commissionBalance}
        />
      </BlockWrapper>

      <BlockWrapper>
        <BlockTitle>{t('reports_funding_affiliate')}</BlockTitle>
        <AffiliateList
          affiliateItems={reportAffiliateList}
          isLoading={isLoading}
        />
      </BlockWrapper>
    </>
  );
};

export default FundingRoundReportDetailsContainer;
