export enum DocumentsEndpoints {
  GET_CLICKWRAP_TEMPLATES = '/admin/clickwrap-templates',
}
export interface IClickWrapTemplateData {
  templateType: DocumentTypes;
  version: number;
  created: string;
  htmlContent: string;
}

export enum DocumentStatuses {
  PENDING = 'pending',
  PROCESSING = 'processing',
  SIGNED = 'signed',
  DECLINED = 'declined',
  EXPIRED = 'expired',
}

export enum DocumentTypes {
  SAFT = 'saft',
  SUPPLEMENTARY_AGREEMENT = 'supplementary_agreement',
  SERVICE_AGREEMENT = 'service_agreement',
  CAMPAIGN_SERVICE_AGREEMENT = 'campaign_service_agreement',
  FUNDING_ROUND_GENERAL_TERMS = 'funding_round_general_terms_and_conditions',
  FUNDING_ROUND_SALE_AND_PURCHASE_AGREEMENT = 'funding_round_sale_and_purchase_agreement',
}

export enum SigningTypes {
  CLICK = 'click',
  SIGN = 'sign',
}

export interface IDocumentTemplateData {
  documentType: DocumentTypes;
  signingType: SigningTypes;
  templateId: string;
}

export interface IContractInformation {
  title: string;
  description: string;
}

export interface ICreateClickWrapTemplateValues {
  templateType: DocumentTypes;
  htmlContent: string;
}

export interface IDocumentData {
  agreementId: string;
  signedDocumentLink: string;
  id: string;
  clickwrapId: string;
  signedOn: string;
  signingType: SigningTypes;
  status: DocumentStatuses;
  type: DocumentTypes;
  envelopeId: string;
  declinedOn: string;
  version: string;
  versionId: string;
  versionNumber: string;
}

// ------------------ Responses --------------------------------

export interface IGetDocumentPreviewDataResponse {
  id?: string;
  templateType: DocumentTypes;
  version: number;
  agreementHtmlContent?: string;
  htmlContent?: string;
}

// ------------------ DTO --------------------------------

export interface ICreateClickWrapTemplateDTO {
  htmlContent: string;
  templateType: DocumentTypes;
}
