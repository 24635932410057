// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledHeaderWrapper,
  StyledTextArea,
} from '../Styled';
import { Link, StyledInput } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Models
import {
  IFundingRoundApplication,
  IRequestDetails,
} from '@/models/requests.model';

// Helpers | Hooks
import { getDateString, getUserLink } from '@/utils';

interface IFundingRoundApplicationRequestDetails {
  request: IRequestDetails | null;
  isLoading?: boolean;
}

const FundingRoundApplicationRequestDetails = ({
  request,
  isLoading,
}: IFundingRoundApplicationRequestDetails) => {
  const { t } = useTranslation(['requestsList']);
  const { firstName, lastName, email, mainContact, role, id, created } =
    request?.requester ?? {};
  const {
    elevatorPitch,
    tgeDate,
    tokenomics,
    tokenomicsMetricsReview,
    fundingStage,
    roadmapOutline,
    marketingStrategy,
    marketAnalysis,
    competitiveLandscape,
    teamsAndPartners,
  } = (request?.data as IFundingRoundApplication) ?? {};

  const goToRequester = getUserLink(role ?? '', id ?? '');
  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <NavigationWrapper>
        <StyledTitle $type="h2">
          {t(`requests_profile_${role}_label`)}
        </StyledTitle>
        <Link to={goToRequester ?? ''} target="_blank">
          <StyledButton type="primary">
            {t('verification_request_go_to_profile')}
          </StyledButton>
        </Link>
      </NavigationWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={firstName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_last_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={lastName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_registration_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={getDateString(created ?? '')} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_email_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={email} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_contact_source')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={mainContact?.source} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_contact')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={mainContact?.contact} disabled />
        </StyledInputWrapper>
      </BlockWrapper>

      <BlockWrapper>
        <StyledText>{t('requests_finding_request_elevator_pitch')}</StyledText>
        <StyledInputWrapper>
          <StyledTextArea value={elevatorPitch} rows={5} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('requests_finding_request_tge')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={tgeDate} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('requests_finding_request_tokenomics')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={tokenomics} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>
          {t('requests_finding_request_tokenomics_metrics')}
        </StyledText>
        <StyledInputWrapper>
          <StyledTextArea value={tokenomicsMetricsReview} rows={5} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('requests_finding_request_stage')}</StyledText>
        <StyledInputWrapper>
          <StyledTextArea value={fundingStage} rows={5} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('requests_finding_request_roadmap')}</StyledText>
        <StyledInputWrapper>
          <StyledTextArea value={roadmapOutline} rows={5} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('requests_finding_request_strategy')}</StyledText>
        <StyledInputWrapper>
          <StyledTextArea value={marketingStrategy} rows={5} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('requests_finding_request_analysis')}</StyledText>
        <StyledInputWrapper>
          <StyledTextArea value={marketAnalysis} rows={5} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>
          {t('requests_finding_request_competitive_landscape')}
        </StyledText>
        <StyledInputWrapper>
          <StyledTextArea value={competitiveLandscape} rows={5} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>
          {t('requests_finding_request_teams_and_partners')}
        </StyledText>
        <StyledInputWrapper>
          <StyledTextArea value={teamsAndPartners} rows={5} disabled />
        </StyledInputWrapper>
      </BlockWrapper>

      <ActionButtons request={request} isLoading={isLoading} />
    </Wrapper>
  );
};

export default FundingRoundApplicationRequestDetails;
