import { IGetListParamsBase } from './api.model';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';
import { IMainContact } from '@/models/sharedProfile.model';
import {
  FundingRoundParticipationStatus,
  IFundingRound,
} from '@/models/fundingRound.model';
import { ISocialChannelKOL } from '@/models/kolsList.model';
import { ICampaign } from '@/models/campaigns.model';
import { IRefundTransaction } from '@/models/payout.model';

export enum ReportsEndpoints {
  GET_FUNDING_REPORTS_LIST = '/admin/funding-rounds/report/list',
  GET_CAMPAIGN_REPORTS_LIST = '/admin/campaigns/report/list',
  FUNDING_ROUND = '/admin/funding-rounds',
  CAMPAIGN = '/admin/campaigns',
  FUNDING_ROUND_FILTER_OPTIONS = '/admin/funding-rounds/filter-options',
  CAMPAIGNS_FILTER_OPTIONS = '/admin/campaigns/filter-options',
}

export interface IReport {
  id: string;
}

export interface IFundingReport extends IReport {
  payoutNetwork: WalletNetworksEnum;
  payoutCurrency: CryptoCurrenciesEnum;
  fundingRoundId: string;
  projectTitle: string;
  fundingTarget: string;
  raised: string;
  fundingRoundTitle: string;
}

export type TAmountByCurrency = {
  [key in CryptoCurrenciesEnum]?: number;
};

export type TNetworkTransfersData = {
  raisedBudget?: TAmountByCurrency & {
    totalByNetwork?: number;
  };
  creditUsed?: TAmountByCurrency & {
    totalByNetwork?: number;
  };
  initialBalance?: TAmountByCurrency & {
    totalByNetwork?: number;
  };
  finalBalance?: TAmountByCurrency & {
    totalByNetwork?: number;
  };
  seedHunterCommissionAmount?: TAmountByCurrency & {
    totalByNetwork?: number;
  };
  projectTransfer?: TAmountByCurrency & {
    totalByNetwork?: number;
  };
};

export type TTransfersDataByNetworks = {
  [key in WalletNetworksEnum]?: TNetworkTransfersData;
};

export type TFundingRoundPayoutBalanceAndTransfers =
  TTransfersDataByNetworks & {
    fundingTarget?: number;
    raisedBudgetTotal?: number;
    creditUsedTotal?: number;
    seedHunterCommissionPercent?: number;
    seedHunterCommissionAmountTotal?: number;
  };

export interface ParticipationParticipantsReportItem {
  created: string;
  participationId?: string;
  participationSk?: string;
  acceptedAt?: string;
  participantAlias?: string;
  participantFirstName?: string;
  participantLastName?: string;
  participationEndDate?: string;
  participantEmail?: string;
  participantMainContact?: IMainContact;
  participantSocials?: ISocialChannelKOL;
  pricePerToken?: number;
  amountFromBonusCredit?: number;
  walletAddressForReceivingTokens?: string;
  totalParticipationAmount?: number;
  currency?: CryptoCurrenciesEnum;
  network?: WalletNetworksEnum;
}

export interface FundingRoundParticipantsReportItem
  extends ParticipationParticipantsReportItem {
  fundingRoundId?: string;
  sk: string;
  id: string;
  fundingRoundTitle?: string;
  refundTransaction?: IRefundTransaction;
  status?: FundingRoundParticipationStatus;
}

export interface CampaignParticipantsReportItem
  extends ParticipationParticipantsReportItem {
  campaignId?: string;
  campaignTitle?: string;
  walletAddress?: string;
  totalPayoutAmount?: number;
  deliveredPosts?: number;
  bookedPosts?: number;
  status?: FundingRoundParticipationStatus;
}

export interface IReportAffiliateItem {
  recipientId: string;
  invitedUserId: string;
  invitedUserName: string;
  invitedUserRole: string;
  fundingRoundId: string;
  fundingRoundTitle: string;
  participationId: string;
  participationSk: string;
  fundingRoundRaisedAmount: number;
  participationAmount: number;
  affiliateCommission: number;
  recipientUserName: string;
  recipientUserRole: string;
}

export interface IFilterOption {
  id: string;
  value: string;
}

// ============= DTO =============

export interface IGetFundingReportsListDTO extends IGetListParamsBase {
  startId?: string;
  fundingRoundId?: string;
  projectId?: string;
}

export interface IGetCampaignReportsListDTO extends IGetListParamsBase {
  startId?: string;
  campaignId?: string;
  projectId?: string;
}

export interface FundingRoundParticipationRefundTransactionRequestDTO {
  participationId: string;
  participationSk: string;
}

// ============== Response ==============

export interface IFundingReportsListResponse {
  items: IFundingReport[];
  hasMore: boolean;
}

export interface ICampaignReportsListResponse {
  items: ICampaign[];
  hasMore: boolean;
}

export type TFundingRoundsReportGeneralDetailData = IFundingRound & {
  balanceReport?: TFundingRoundPayoutBalanceAndTransfers;
};

export type FundingRoundParticipantsReportResponse =
  FundingRoundParticipantsReportItem[];

export type CampaignParticipantsReportResponse =
  CampaignParticipantsReportItem[];

export type ReportAffiliateResponse = IReportAffiliateItem[];

export interface IFundingRoundFilterOptionsResponse {
  projects: IFilterOption[];
  fundingRounds: IFilterOption[];
}

export interface ICampaignReportsFilterOptionsResponse {
  projects: IFilterOption[];
  campaigns: IFilterOption[];
}
