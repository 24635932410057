import styled from 'styled-components';
import { Button } from '@/ui';
import { DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-bottom: 1.5rem;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('18', '24', 'BOLD')};
  margin-bottom: 0.5rem;
  text-align: center;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('28', '38', 'BOLD')};
    margin-bottom: 1rem;
  }
`;
