import { useTranslation } from 'react-i18next';
import { useGetNumbersOnlyRule, useLengthValidationRule } from '@/hooks';
import { SettingsFieldsNames, TopBarsSettingsFieldsNames } from '../helpers';

const useGetFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const numbersOnly = useGetNumbersOnlyRule({ t });
  const lengthValidationRule = useLengthValidationRule({ t, min: 3, max: 255 });
  const topBarLengthValidationRule = useLengthValidationRule({
    t,
    min: 3,
    max: 200,
  });

  return {
    [SettingsFieldsNames.FACEBOOK_PRICE]: [numbersOnly],
    [SettingsFieldsNames.FIRST_STAGE_DAYS]: [numbersOnly],
    [SettingsFieldsNames.INVESTMENT_TARGET_BIAS_PERCENT]: [numbersOnly],
    [SettingsFieldsNames.FOLLOWERS_MULTIPLIER]: [numbersOnly],
    [SettingsFieldsNames.INSTAGRAM_PRICE]: [numbersOnly],
    [SettingsFieldsNames.YOUTUBE_PRICE]: [numbersOnly],
    [SettingsFieldsNames.TWITTER_PRICE]: [numbersOnly],
    [SettingsFieldsNames.HOURS_FOR_CONTRACT_SIGNING]: [numbersOnly],
    [SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING]: [numbersOnly],
    [SettingsFieldsNames.CAMPAIGN_COMMISSION]: [numbersOnly],
    [SettingsFieldsNames.DAYS_FOR_LABEL_NEW]: [numbersOnly],
    [SettingsFieldsNames.AVERAGE_DATA_INSTAGRAM]: [numbersOnly],
    [SettingsFieldsNames.AVERAGE_DATA_TWITTER]: [numbersOnly],
    [SettingsFieldsNames.AVERAGE_DATA_FACEBOOK]: [numbersOnly],
    [SettingsFieldsNames.AVERAGE_DATA_YOUTUBE]: [numbersOnly],
    [SettingsFieldsNames.FUNDING_BUCKET]: [numbersOnly],
    [SettingsFieldsNames.FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS]: [
      numbersOnly,
    ],
    [SettingsFieldsNames.SAFT_CLICK]: [lengthValidationRule],
    [SettingsFieldsNames.SUPLIMENTARY_AGREEMENT_CLICK]: [lengthValidationRule],
    [SettingsFieldsNames.SUPLIMENTARY_AGREEMENT_SIGN]: [lengthValidationRule],
    [SettingsFieldsNames.SERVICE_AGREEMENT_SIGN]: [lengthValidationRule],
    [SettingsFieldsNames.MARKETING_AFFILIATE_COMMISSION]: [numbersOnly],
    [SettingsFieldsNames.CAMPAIGN_SIGN_UP_PERIOD_OFFSET]: [numbersOnly],
    [SettingsFieldsNames.PARTICIPATION_MAX]: [numbersOnly],
    [SettingsFieldsNames.PARTICIPATION_MIN]: [numbersOnly],
    [SettingsFieldsNames.MAX_COORDINATED_FOLLOWERS]: [numbersOnly],
    [SettingsFieldsNames.MIN_FOLLOWERS]: [numbersOnly],
    [SettingsFieldsNames.MIN_ENGAGEMENT_RATE]: [numbersOnly],
    [SettingsFieldsNames.MAX_MONTH_REPORT]: [numbersOnly],
    [TopBarsSettingsFieldsNames.TEXT]: [topBarLengthValidationRule],
  };
};

export default useGetFormValidationRules;
