import { httpPost } from './httpClient';
import {
  DataExportsEndpoints,
  IDataExportsResponse,
  IGetEntityListExportDTO,
  IGetPostsExportDTO,
} from '@/models/dataExports.model';

class DataExportsAPI {
  static exportAllPayouts() {
    return httpPost<undefined, IDataExportsResponse>(
      `${DataExportsEndpoints.ROOT}${DataExportsEndpoints.PAYOUTS}`,
    );
  }

  static exportFundingRoundsPayouts() {
    return httpPost<undefined, IDataExportsResponse>(
      `${DataExportsEndpoints.ROOT}${DataExportsEndpoints.FUNDING_ROUNDS_PAYOUTS}`,
    );
  }

  static exportFundingRoundsRefunds() {
    return httpPost<undefined, IDataExportsResponse>(
      `${DataExportsEndpoints.ROOT}${DataExportsEndpoints.FUNDING_ROUNDS_REFUNDS}`,
    );
  }

  static exportPayoutsByCampaignId(campaignId: string) {
    return httpPost<undefined, IDataExportsResponse>(
      `${DataExportsEndpoints.ROOT}${
        DataExportsEndpoints.PAYOUTS
      }/${encodeURIComponent(campaignId)}`,
    );
  }

  static exportFundingRefundById(fundingRoundId: string) {
    return httpPost<undefined, IDataExportsResponse>(
      `${DataExportsEndpoints.ROOT}${
        DataExportsEndpoints.FUNDING_ROUNDS_REFUNDS
      }/${encodeURIComponent(fundingRoundId)}`,
    );
  }

  static exportKolsList(data: IGetEntityListExportDTO) {
    return httpPost<IGetEntityListExportDTO, IDataExportsResponse>(
      `${DataExportsEndpoints.ROOT}${DataExportsEndpoints.KOLS}`,
      data,
    );
  }

  static exportProjectsList(data: IGetEntityListExportDTO) {
    return httpPost<IGetEntityListExportDTO, IDataExportsResponse>(
      `${DataExportsEndpoints.ROOT}${DataExportsEndpoints.PROJECTS}`,
      data,
    );
  }

  static exportFundingReport(id: string) {
    return httpPost<IGetEntityListExportDTO, IDataExportsResponse>(
      `${DataExportsEndpoints.EXPORTS_PDF}${DataExportsEndpoints.FUNDING_ROUND_REPORTS}/${id}`,
    );
  }

  static exportCampaignReport(id: string) {
    return httpPost<IGetEntityListExportDTO, IDataExportsResponse>(
      `${DataExportsEndpoints.EXPORTS_PDF}${DataExportsEndpoints.CAMPAIGN_REPORTS}/${id}`,
    );
  }

  static exportPostsByCampaignId(data: IGetPostsExportDTO) {
    return httpPost<IGetPostsExportDTO, IDataExportsResponse>(
      `${DataExportsEndpoints.POSTS}/${encodeURIComponent(
        data.campaignId ?? '',
      )}`,
      data,
    );
  }
}

export default DataExportsAPI;
