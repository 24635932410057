// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  DeliveryWrapper,
  StyledInfoTitles,
  StyledTitle,
  StyledTitles,
  StyledHeader,
  StyledActionButton,
  StyledButtonsWrap,
  StyledHint,
} from './KOLsRankingStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import { WarningIcon } from '@/icons';
import KOLsRankingCard from './KOLsRankingCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IKolsRankingItem } from '@/models/projects.model';

// Helpers
import { useInfiniteScroll } from '@/hooks';

interface IKOLsRanking {
  kolsRankingData: {
    items: IKolsRankingItem[] | null;
    hasMore: boolean;
  };
  getNextData: () => void;
  refreshKolsRanking: () => void;
  simulateKOLsRankingCallback: () => void;
  isLoading: boolean;
}

const KOLsRanking = ({
  kolsRankingData,
  simulateKOLsRankingCallback,
  getNextData,
  refreshKolsRanking,
  isLoading,
}: IKOLsRanking) => {
  const { t } = useTranslation(['projectsList']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedKolsRankingList = useMemo(() => {
    return kolsRankingData?.items?.map((data) => {
      return <KOLsRankingCard data={data} key={data.processedAt} />;
    });
  }, [kolsRankingData]);

  useInfiniteScroll({
    callback: getNextData,
    ref: loaderRef,
    isLoading,
    hasMore: kolsRankingData?.hasMore,
  });

  return (
    <DeliveryWrapper>
      <StyledHeader>
        <StyledTitle>{t('projects_kols_ranking_title')}</StyledTitle>
        <StyledButtonsWrap>
          <StyledActionButton type="primary" onClick={refreshKolsRanking}>
            {t('projects_kols_ranking_refresh_ranking_list')}
          </StyledActionButton>
          <StyledActionButton
            type="primary"
            onClick={simulateKOLsRankingCallback}
          >
            {t('projects_kols_ranking_simulate_ranking')}
          </StyledActionButton>
        </StyledButtonsWrap>
      </StyledHeader>
      <StyledHint>
        <WarningIcon />
        {t('projects_kols_ranking_refresh_warn')}
      </StyledHint>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('projects_kols_ranking_influencer')}</StyledTitles>
          <StyledTitles>{t('projects_kols_ranking_twitter')}</StyledTitles>
          <StyledTitles>{t('projects_kols_ranking_followers')}</StyledTitles>
          <StyledTitles>{t('projects_kols_ranking_post_price')}</StyledTitles>
          <StyledTitles>{t('projects_kols_ranking_impact_score')}</StyledTitles>
          <StyledTitles>
            {t('projects_kols_ranking_project_match')}
          </StyledTitles>
          <StyledTitles>
            {t('projects_kols_ranking_engagement_rate')}
          </StyledTitles>
          <StyledTitles>{t('projects_kols_ranking_reach_rate')}</StyledTitles>
          <StyledTitles>{t('projects_kols_ranking_grade_score')}</StyledTitles>
          <StyledTitles>
            {t('projects_kols_ranking_coordinated_followers')}
          </StyledTitles>
          <StyledTitles>{t('projects_kols_ranking_processed_at')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedKolsRankingList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </DeliveryWrapper>
  );
};

export default KOLsRanking;
