import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button, Select } from '@/ui';
import { CampaignStatuses } from '@/models/campaigns.model';
import { FormItem } from '@/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StyledHeaderBlockWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    gap: 1.5rem;
  }
`;

export const StyledHeaderText = styled.div`
  color: ${COLORS.LIQUORICE_BLACK};
`;

export const StyledBadge = styled.div<{ $status?: CampaignStatuses }>`
  display: flex;
  width: auto;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  color: ${COLORS.WHITE};
  ${pickThemeFontStyles('10', '16', 'BOLD')};
  ${({ $status }) => {
    switch ($status) {
      case CampaignStatuses.COMPLETED:
        return `background: ${COLORS.LIGHT_GREEN};`;
      case CampaignStatuses.ARCHIVED:
      case CampaignStatuses.CANCELED:
      case CampaignStatuses.EXPIRED:
        return `background: ${COLORS.ROSE};`;
      case CampaignStatuses.PAUSED:
      case CampaignStatuses.WAITING_FOR_PAYMENT:
        return `background: ${COLORS.YELLOW};`;
      case CampaignStatuses.ACTIVE:
      case CampaignStatuses.DELIVERY_FINISHED:
        return `background: ${COLORS.TURQUOISE_BLUE};`;
      default:
        return `background: ${COLORS.ATOMIC_TANGERINE};`;
    }
  }}
`;

export const StyledHeaderBlock = styled(BlockLayout)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '20', 'SEMI_BOLD')};
  color: ${COLORS.LIQUORICE_BLACK};
`;

export const StyledDeliveryWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledButton = styled(Button)`
  display: flex;
  width: 8.6rem;
  height: 2rem !important;
  background: ${COLORS.WHITE};
  color: ${COLORS.CORN_FLOWER_BLUE};
  box-shadow: 0px 4px 20px -2px #0c1a4b1a !important;
  :hover {
    background: ${COLORS.WHITE}!important;
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
  svg {
    transform: scale(1.5);
    margin: 0.5rem 0rem 0rem 1.5rem;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  margin-bottom: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('16', '25', 'BOLD')};
    margin-bottom: 1rem;
  }
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    align-items: flex-start;
  }

  :first-child {
    flex: 1.5;
  }

  :nth-last-child(3) {
    flex: 5;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      align-items: center;
    }
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  :last-child {
    align-items: flex-start;
  }

  :first-child {
    flex: 1.5;
  }

  :nth-last-child(3) {
    flex: 5;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      align-items: center;
    }
  }
`;

export const StyledInfoTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledA = styled.a`
  color: ${COLORS.BLUE_PRIMARY};
  word-break: break-all;
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  width: 2rem;
`;

export const StyledSelect = styled(Select)<{ $isWide?: boolean }>`
  &.ant-select {
    &-single {
      & .ant-select-selector {
        background: ${COLORS.WHITE};
        height: 2.25rem;
        width: ${({ $isWide }) => ($isWide ? '100%' : '8rem')};
      }
    }
    .ant-select-clear {
      background: ${COLORS.WHITE};
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &.ant-select {
      &-single {
        & .ant-select-selector {
          width: ${({ $isWide }) => ($isWide ? '100%' : '10rem')};
        }
      }
    }
  }
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0.5rem;

  &&& .ant-select-show-arrow {
    margin-bottom: 0;
  }
`;

export const StyledFieldWrap = styled.div`
  position: relative;
`;

export const StyledInfoWrap = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.2rem;
  z-index: 999;
`;

export const IconWrap = styled.div<{ $position?: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 1rem;
  cursor: pointer;
  svg {
    path {
      fill: ${COLORS.BLACK};
    }
  }
`;
