import {
  ICompanyInfo,
  IProject,
  IUpdateProjectDetailsDTO,
  VerificationLevel,
} from '@/models/projects.model';
import { CompanyInfoFieldsNames, TProjectInfo } from '../types';
import { IMainContact } from '@/models/sharedProfile.model';
import { SocialChannels } from '@/models/socialChannel.model';
import { buildAllowedSocialChannels } from '../helpers';

class ProjectInfoDTO implements IUpdateProjectDetailsDTO {
  firstName?: string;
  lastName?: string;
  formAddress?: string;
  email?: string;
  companyType?: string;
  companyInfo?: ICompanyInfo;
  verificationLevel?: VerificationLevel;
  mainContact?: IMainContact;
  allowedSocialChannels?: SocialChannels[];

  constructor(values: TProjectInfo, projectInfo: IProject) {
    this.firstName = values[CompanyInfoFieldsNames.FIRST_NAME];
    this.lastName = values[CompanyInfoFieldsNames.LAST_NAME];
    this.formAddress = values[CompanyInfoFieldsNames.FORM_OF_ADDRESS];
    this.verificationLevel = values[CompanyInfoFieldsNames.VERIFICATION_LEVEL];
    this.allowedSocialChannels = buildAllowedSocialChannels(
      values[
        CompanyInfoFieldsNames.ALLOWED_SOCIAL_CHANNELS
      ] as SocialChannels[],
      SocialChannels.TWITTER,
    );
    this.companyInfo = {
      ...values.companyInfo,
      ...projectInfo.companyInfo,
    };
    this.mainContact = values.mainContact;
  }
}

export default ProjectInfoDTO;
