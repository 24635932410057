/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-ts-comment  */
import { useEffect } from 'react';

// Modules
import { logOut, setIsAuthorized, setIsLoading } from '../feature/slice';

// Helpers
import { appCookiesStorage, isUndefined } from '@/utils';
import { useAppDispatch, useAppState, useCognito } from '@/hooks';
import { selectAuthIsLoading, selectIsAuthorized } from '../feature/selectors';
import {
  AUTHORIZATION_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from '@/constants';
import { env } from '../../../config/env';

const useSessionRefresh = () => {
  const isAuthorized = useAppState(selectIsAuthorized);
  const isLoading = useAppState(selectAuthIsLoading);
  const { cognitoUser } = useCognito();
  const dispatch = useAppDispatch();

  const refreshSession = async () => {
    const refreshToken = appCookiesStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    const authToken = appCookiesStorage.getItem(
      AUTHORIZATION_TOKEN_STORAGE_KEY,
    );

    // No refresh token - log out
    if (isUndefined(refreshToken)) {
      dispatch(logOut());
      dispatch(setIsLoading(false));
    }

    // All tokens are present - set is authorized
    if (!isUndefined(refreshToken) && !isUndefined(authToken)) {
      dispatch(setIsAuthorized(true));
      dispatch(setIsLoading(false));
    }

    // No auth token, present refresh token - refresh session
    if (refreshToken && !isAuthorized) {
      const session = await cognitoUser?.refreshSession(refreshToken);

      const updatedJwtToken = session?.getIdToken()?.getJwtToken();
      const updatedRefreshToken = session?.getRefreshToken()?.getToken();

      if (!updatedJwtToken || !updatedRefreshToken) {
        throw new Error('Session refresh Error');
      }
      appCookiesStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, updatedRefreshToken);

      appCookiesStorage.setItem(
        AUTHORIZATION_TOKEN_STORAGE_KEY,
        updatedJwtToken,
      );
    }
  };

  useEffect(() => {
    if (env.MAINTENANCE_MODE) {
      dispatch(logOut());
    }
  }, [isLoading]);

  useEffect(() => {
    refreshSession();
  }, [cognitoUser, dispatch, isAuthorized, isLoading]);
};

export default useSessionRefresh;
