// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  DetailsWrapper,
  SliderWrapper,
  StyledBlockTitle,
  StyledColumnBlock,
  StyledSlider,
  StyledSubTitle,
  StyledTermsText,
  StyledValue,
  StyledValues,
  StyledLabel,
  StyledCapitalValue,
} from './FundingOverviewStyles';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Helpers
import { getDateString, getPercent, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';
import { fundingTargetCalculation } from '../../helpers';

interface IDealTerms {
  fundingRound: IFundingRound;
}

const DealTerms = ({ fundingRound }: IDealTerms) => {
  const { t } = useTranslation(['fundingRound']);
  const tgeDate = getDateString(
    fundingRound?.plannedTGEDate ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );

  const raised = fundingRound?.raisedAmount ?? 0;
  const fundingTarget = fundingTargetCalculation(
    fundingRound ?? ({} as IFundingRound),
  );
  const fundingReached =
    getPercent(raised, fundingTarget) +
    (fundingRound?.progressBarAdditionalPercentage || 0);

  return (
    <DetailsWrapper>
      <StyledBlockTitle>{t('funding_overview_deal_terms')}</StyledBlockTitle>
      <StyledSubTitle>{t('funding_overview_funding_target')}</StyledSubTitle>
      {fundingRound && (
        <StyledValue>
          $ {numberWithCommas(fundingTargetCalculation(fundingRound) ?? 0)}
        </StyledValue>
      )}
      <StyledValues>
        <StyledLabel>{t('funding_round_money_for_tokens')}</StyledLabel>
        <StyledCapitalValue>
          {Math.min(fundingReached ?? 0, 100)} %
        </StyledCapitalValue>
      </StyledValues>
      <SliderWrapper>
        <StyledSlider value={fundingReached} max={100} />
      </SliderWrapper>
      <StyledColumnBlock>
        {t('funding_overview_phase')}
        <StyledTermsText>
          {t(`funding_${fundingRound?.phase}_phase`)}
        </StyledTermsText>
      </StyledColumnBlock>
      <StyledColumnBlock>
        {t('funding_overview_token_price')}
        <StyledTermsText>
          $ {numberWithCommas(fundingRound?.pricePerToken ?? 0, 5)}
        </StyledTermsText>
      </StyledColumnBlock>
      <StyledColumnBlock>
        {t('funding_overview_vesting_period')}
        <StyledTermsText>{fundingRound?.lockUpVestingSummary}</StyledTermsText>
      </StyledColumnBlock>
      <StyledColumnBlock>
        {t('funding_overview_planned_tge_date')}
        <StyledTermsText>{tgeDate}</StyledTermsText>
      </StyledColumnBlock>
    </DetailsWrapper>
  );
};

export default DealTerms;
